import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'
import { GetPartnerProductsResponse } from '../search/search'

type CasIdApi = {
  get: (_casId: string) => Promise<GetPartnerProductsResponse>,
}

export const casId: CasIdApi = {
  get: (_casId: string): Promise<GetPartnerProductsResponse> =>
    apiCreator
      .get<GetPartnerProductsResponse>(
        `${API_URL.PARTNER_PRODUCTS}/request`,
        {
          params: {
            cas_id: _casId,
          },
        },
      )
      .then(({ data }) => data),
}
