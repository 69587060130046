import { DataTableSearchFn } from '@appscience/data-table'
import { isNullable } from '@appscience/utils'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OrderPositionsTableGlobalFiltersData } from '../../../../../store/order-positions-table/reducer'
import { selectOrderPositionsTableGlobalFilters } from '../../../../../store/order-positions-table/selectors'
import { caseInsensitiveSubstr } from '../../../../../utils/string'
import { ORDER_ITEM_STATUS_TO_BADGE_CONFIG, unixTimestampToFormattedDate } from '../../../OrderPositions.utils'
import { TableOrderPosition } from '../OrderPositionsTable.type'

export function useGlobalFilteredTableOrderPositions(tableOrderPositions: Array<TableOrderPosition>): Array<TableOrderPosition> {
  const {
    clientNameIds = [],
    nameIds = [],
    orderIds = [],
  }: OrderPositionsTableGlobalFiltersData = useSelector(selectOrderPositionsTableGlobalFilters())

  return useMemo(() => {
    const clientNameIdsSet = clientNameIds.length > 0
      ? new Set(clientNameIds)
      : null
    const nameIdsSet = nameIds.length > 0
      ? new Set(nameIds)
      : null
    const orderIdsSet = orderIds.length > 0
      ? new Set(orderIds)
      : null


    if ([clientNameIdsSet, nameIdsSet, orderIdsSet].every(isNullable)) {
      return tableOrderPositions
    }

    return tableOrderPositions.filter(({ clientName, name, orderId }) =>
      isInFilter(clientName, clientNameIdsSet)
      && isInFilter(name, nameIdsSet)
      && isInFilter(`${orderId}`, orderIdsSet),
    )
  }, [tableOrderPositions, clientNameIds, nameIds, orderIds])
}

function isInFilter(orderId: string, filteredIdsSet: Set<string> | null): boolean {
  return filteredIdsSet === null || filteredIdsSet.has(orderId)
}

export function useOrderPositionsTableSearchFn(): DataTableSearchFn<TableOrderPosition> {
  return useCallback((orderPosition: TableOrderPosition, search: string) =>
    caseInsensitiveSubstr(orderPosition.clientName, search)
    || caseInsensitiveSubstr(`${orderPosition.orderId}`, search)
    || caseInsensitiveSubstr(`${orderPosition.name}`, search)
    || caseInsensitiveSubstr(unixTimestampToFormattedDate(orderPosition.deadline), search)
    || caseInsensitiveSubstr(unixTimestampToFormattedDate(orderPosition.expectedDeliveryDate), search)
    || caseInsensitiveSubstr(orderPosition.statusDescription || ORDER_ITEM_STATUS_TO_BADGE_CONFIG[orderPosition.status]?.message, search)
    || caseInsensitiveSubstr(orderPosition.causeOfWaiting, search)
    || caseInsensitiveSubstr(orderPosition.comment, search)
    || caseInsensitiveSubstr(orderPosition.procurementComment, search)
    || caseInsensitiveSubstr(orderPosition.sdComment, search)
  , [])
}
