import { Button, Card, Col, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ProposalGenerationAction } from '../ProposalGenerationAction'
import { BillGenerationAction } from '../BillGenerationAction'
import { SpecificationGenerationAction } from '../SpecificationGenerationAction'
import { Warnings } from '../Warnings'

type ProposalActionsProps = {
  isLoading: boolean;
  isGenerationButtonDisabled: boolean;
  isAttachButtonDisabled: boolean;
  warnings: Array<string>;
  generateProposal: () => void;
  generateBill: () => void;
  generateSpecification: () => void;
  attachProposal: () => void;
}

export function ProposalActions({
  isLoading,
  isGenerationButtonDisabled,
  isAttachButtonDisabled,
  warnings,
  generateBill,
  generateProposal,
  generateSpecification,
  attachProposal,
}: ProposalActionsProps) {
  return (
    <Card>
      <Row>
        {isLoading && (
          <Col span={24}>
            <div className='d-flex justify-content-center'>
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </div>
          </Col>
        )}
        {!isLoading && (
          <>
            <ProposalGenerationAction
              isGenerationButtonDisabled={isGenerationButtonDisabled}
              generateProposal={generateProposal}
            />
            <BillGenerationAction
              isGenerationButtonDisabled={isGenerationButtonDisabled}
              generateBill={generateBill}
            />
            <SpecificationGenerationAction
              isGenerationButtonDisabled={isGenerationButtonDisabled}
              generateSpecification={generateSpecification}
            />
            <Warnings
              warnings={warnings}
            />

            <Col span={24}>
              <Button
                type='primary'
                block
                disabled={isAttachButtonDisabled}
                onClick={attachProposal}
              >
                Прикрепить КП в AmoCRM и Личный кабинет
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
}
