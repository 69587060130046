import React from 'react'
import { SearchResultComponent } from './search-result/index'
import { Button } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { selectAddToCartBtnDisable, selectCartItems } from '../../../../../store/cart/cart.selectors'
import { useSelector } from 'react-redux'
import { ProductType } from '../../../../../store/search/types'
import { CartItem } from '../../../../../store/cart/types'
import { Suggestion } from '../../../../../api/search/search'
import cn from 'classnames'

type SearchLabelProps = {
  index: number;
  suggestions: Array<Suggestion>;
  theme: string;
  addToBasketFromSearchResult: (
    e: React.MouseEvent<HTMLElement>,
    product: ProductType,
    basketProducts: Array<CartItem>,
  ) => void;
}

export const SearchLabel: React.FC<SearchLabelProps> = props => {
  const {
    index,
    suggestions,
    theme,
    addToBasketFromSearchResult,
  } = props

  const cartItems = useSelector(selectCartItems)
  const isAddToCartDisabled: boolean = useSelector(selectAddToCartBtnDisable)
  const isLightTheme = theme === 'light'

  return (
    <div
      key={`${index}`}
      className='search-result-dropdown-wrapper d-flex align-items-center'
    >
      <div
        className='search-result-dropdown-top-holder'
        style={{
          width: 'calc(100% - 60px)',
        }}
      >
        <SearchResultComponent
          suggestion={suggestions[index]}
        />
      </div>
      <div className='search-result-basket-block ml-2'>
        <Button
          className='search-result-dropdown-item-add-to-cart-btn'
          size='small'
          disabled={isAddToCartDisabled}
          onClick={e => {
            addToBasketFromSearchResult(
              e,
              suggestions[index].data,
              cartItems,
            )
          }}
        >
          <ShoppingCartOutlined
            className='cart-outline'
            style={{
              fontSize: 15,
            }}
          />
        </Button>
      </div>
      <div
        className={cn(
          {
            'bg-gray-lighter': isLightTheme,
            'bg-gray': !isLightTheme,
          },
        )}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 1,
        }}
      > </div>
    </div>
  )
}
