import { joinStrings } from '@appscience/utils'
import { Button, Checkbox, ColorScheme, TextInput } from '@mantine/core'
import React, { useState } from 'react'
import { caseInsensitiveSubstr } from '../../../../utils/string'

function mode<T>(light: T, dark: T): (v: ColorScheme) => T {
  return (colorScheme: 'light' | 'dark') => (colorScheme === 'dark' ? dark : light)
}

export interface SelectListItem<ID extends string> {
  value: ID,
  label?: string,
}

interface SaveButtonProps<ID extends string> {
  text?: string,
  onClick: (selectedIds: Array<ID>) => void,
}

interface OnChangeFnParams<ID extends string> {
  selectedIds: Array<ID>,
  id: ID,
  checked: boolean,
}

type CanToggleFnParams<ID extends string> = OnChangeFnParams<ID>

export interface SelectListPopoverProps<ID extends string> {
  selectedIds: Array<ID>,
  data: Array<SelectListItem<ID>>,
  saveButton: SaveButtonProps<ID>,
  onChange?: (v: OnChangeFnParams<ID>) => void,
  canToggle?: (v: CanToggleFnParams<ID>) => boolean,
  listClassName?: string,
  className?: string,
  searchable?: boolean,
}

export function MultiSelectListPopover<ID extends string>({
  selectedIds,
  data,
  onChange,
  saveButton,
  canToggle,
  listClassName,
  className,
  searchable,
}: SelectListPopoverProps<ID>) {
  const [search, setSearch] = useState('')
  const [selectedValues, setSelectedValues] = useState(selectedIds)
  const selectedSet = new Set(selectedValues)
  const filteredData = search
    ? data.filter(x => caseInsensitiveSubstr(getTitle(x), search))
    : data

  const onChangeFn = (event: React.ChangeEvent<HTMLInputElement>, id: ID) => {
    const newValues = event.currentTarget.checked
      ? [...selectedValues, id]
      : selectedValues.filter(x => x !== id)
    const fnParams = {
      id,
      checked: event.currentTarget.checked,
      selectedIds: newValues,
    }

    if (canToggle && !canToggle(fnParams)) {
      return
    }
    onChange && onChange(fnParams)
    setSelectedValues(newValues)
  }

  return (
    <div className={joinStrings('flex flex-col cursor-pointer shadow-inner', className)}>
      {searchable && <TextInput
        value={search}
        onChange={event => setSearch(event.currentTarget.value)}
        className='shadow-sm p-1'
      />}
      <div className={joinStrings('overflow-y-auto', listClassName)}>
        {filteredData.map(item => (
          <Checkbox
            key={item.value}
            label={getTitle(item)}
            checked={selectedSet.has(item.value)}
            onChange={event => onChangeFn(event, item.value)}
            sx={({ colors, colorScheme }) => ({
              '&:hover': {
                backgroundColor: mode(colors.blue[1], colors.blue[9])(colorScheme),
              },
            })}
            className='px-3 py-2 duration-200'
          />
        ))}
      </div>
      {!!saveButton && (
        <div className='flex pb-2 pt-3 px-2 justify-end'>
          <Button
            variant='light'
            className='bg-transparent mr-2'
            disabled={selectedValues.length === 0}
            onClick={() => setSelectedValues([])}
          >
            {/* {i18n_get.t('Common.Reset')} */}
            Reset
          </Button>
          <Button onClick={() => saveButton.onClick(selectedValues)}>
            {/* {saveButton.text || i18n_get.t('Common.Apply')} */}
            {saveButton.text || 'Apply'}
          </Button>
        </div>
      )}
    </div>
  )
}

function getTitle<ID extends string>(item: SelectListItem<ID>) {
  return item.label || item.value
}