import { Alert, Col, List } from 'antd'

type WarningsProps = {
  warnings: Array<string>;
}

export function Warnings({ warnings }: WarningsProps) {
  if (warnings.length === 0) {
    return null
  }

  return (
    <Col
      span={24}
      className='mb-4'
    >
      <Alert
        message='Есть проблемы с КП'
        description={(
          <List>
            {warnings.map((warning: string) => (
              <List.Item>
                {warning}
              </List.Item>
            ))}
          </List>
        )}
        type='warning'
        showIcon
      />
    </Col>
  )
}
