import { createSlice } from '@reduxjs/toolkit'
import { ActionType } from '../types'
import { PipelineEnum } from '../../api/proposal/proposal'

const initialState = {
  googleSheetsLink: '',
  rubGoogleSheetsLink: '',
  usdGoogleSheetsLink: '',
  pdfLink: '',
  docLink: '',
  billPdfLink: '',
  importTableLink: '',
  specificationPdfLink: '',
  specificationDocLink: '',
  linkToTable: '',
  orderNumber: '',
  disabledOrderForm: false,
  importOrGenerateLoading: false,
  pipeline: PipelineEnum.Supply,
}

const generateSlice = createSlice({
  name: 'generate',
  initialState: { ...initialState },
  reducers: {
    setGoogleSheetsLinkAction(state, action: ActionType<string>) {
      state.googleSheetsLink = action.payload
    },
    setRubGoogleSheetsLinkAction(state, action: ActionType<string>) {
      state.rubGoogleSheetsLink = action.payload
    },
    setUsdGoogleSheetsLinkAction(state, action: ActionType<string>) {
      state.usdGoogleSheetsLink = action.payload
    },
    setPdfLinkAction(state, action: ActionType<string>) {
      state.pdfLink = action.payload
    },
    setDocLinkAction(state, action: ActionType<string>) {
      state.docLink = action.payload
    },
    setBillPdfLinkAction(state, action: ActionType<string>) {
      state.billPdfLink = action.payload
    },
    setImportTablePdfLinkAction(state, action: ActionType<string>) {
      state.importTableLink = action.payload
    },
    setSpecificationPdfLinkAction(state, action: ActionType<string>) {
      state.specificationPdfLink = action.payload
    },
    setSpecificationDocLinkAction(state, action: ActionType<string>) {
      state.specificationDocLink = action.payload
    },
    setLinkToTableAction(state, action: ActionType<string>) {
      state.linkToTable = action.payload
    },
    setOrderNumberAction(state, action: ActionType<string>) {
      state.orderNumber = action.payload
    },
    setDisabledOrderFormAction(state, action: ActionType<boolean>) {
      state.disabledOrderForm = action.payload
    },
    setImportOrGenerateLoading(state, action: ActionType<boolean>) {
      state.importOrGenerateLoading = action.payload
    },
    setPipeline(state, action: ActionType<PipelineEnum>) {
      state.pipeline = action.payload
    },
  },
})

export const generateReducer = generateSlice.reducer
export const {
  setGoogleSheetsLinkAction,
  setRubGoogleSheetsLinkAction,
  setUsdGoogleSheetsLinkAction,
  setPdfLinkAction,
  setDocLinkAction,
  setBillPdfLinkAction,
  setImportTablePdfLinkAction,
  setSpecificationPdfLinkAction,
  setSpecificationDocLinkAction,
  setOrderNumberAction,
  setLinkToTableAction,
  setDisabledOrderFormAction,
  setImportOrGenerateLoading,
  setPipeline,
} = generateSlice.actions
