import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { setIsProposalGenerationLoading, setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'
import { getApi } from '../../../api/getApi'
import { getIdFromGoogleSpreadsheetUrl } from '../../../views/proposal-view/content/ProposalGeneration/ProposalGeneration.utils'
import { GenerateProposalAsyncResponse, ProposalData, ProposalWarning } from '../../../api/proposal/proposal'
import { setDocLinkAction, setGoogleSheetsLinkAction, setPdfLinkAction, setRubGoogleSheetsLinkAction, setUsdGoogleSheetsLinkAction } from '../../generate-table/reducer'

export const generateProposalAction = createAsyncThunk<
  {
    spreadsheet: string;
    spreadsheetRub: string;
    spreadsheetUsd: string;
    pdf: string;
    doc: string;
  },
  {
    orderNumber: string;
    spreadsheetLink: string;
  }
>(
  'cart/generateProposal',
  async ({
    orderNumber,
    spreadsheetLink,
  }, { dispatch }) => {
    dispatch(setIsProposalGenerationLoading(true))
    dispatch(setProposalGenerationWarnings([]))

    try {
      const { job_id: jobId }: GenerateProposalAsyncResponse =
        await getApi()
          .proposal
          .generateProposalAsync({
            id: orderNumber,
            spreadsheetId: getIdFromGoogleSpreadsheetUrl(spreadsheetLink),
          })

      const proposalData: ProposalData =
        await getApi()
          .proposal
          .getJobResult<ProposalData>({
            jobId,
          })

      const {
        Pdf: pdf,
        Doc: doc,
        Spreadsheet: spreadsheet,
        SpreadsheetUsd: spreadsheetUsd,
        SpreadsheetRub: spreadsheetRub,
        Warnings: responseWarnings,
      } = proposalData

      dispatch(setGoogleSheetsLinkAction(spreadsheet))
      dispatch(setRubGoogleSheetsLinkAction(spreadsheetRub))
      dispatch(setUsdGoogleSheetsLinkAction(spreadsheetUsd))
      dispatch(setPdfLinkAction(pdf))
      dispatch(setDocLinkAction(doc))

      const newWarnings: Array<string> =
        (responseWarnings ?? [])
          .map(({ Text: text }: ProposalWarning) => text)
      dispatch(setProposalGenerationWarnings(newWarnings))
      if (newWarnings.length) {
        notification.warning({
          message: 'Предупреждение',
          description: 'Коммерческое предложение сгенерировано, но не готово к отправке.',
          duration: 5,
        })
      }
      else {
        notification.success({
          message: 'Успешно',
          description: 'Коммерческое предложение успешно сгенерировано!',
        })
      }

      return {
        spreadsheet,
        spreadsheetRub,
        spreadsheetUsd,
        pdf,
        doc,
      }
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при генерации коммерческого предложения',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }
    finally {
      dispatch(setIsProposalGenerationLoading(false))
    }

    return {
      spreadsheet: '',
      spreadsheetRub: '',
      spreadsheetUsd: '',
      pdf: '',
      doc: '',
    }
  },
)
