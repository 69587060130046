import {MutableRefObject} from 'react'
import {SnipState} from '../hooks/useSnip'

export const destroyObserver = (state: MutableRefObject<SnipState>): void => {
  const { element } = state.current

  if (element.observer) {
    element.observer.disconnect()
    element.prevWidth = undefined
    element.prevHeight = undefined
    element.observer = undefined
  }
}
