import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'

export const enum UserType {
  Admin = 'admin',
  User = 'user',
}

export type User = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: UserType;
  gmail: string;
}

type AddUserRequest = User

type UsersApiType = {
  addUser: (request: AddUserRequest) => Promise<void>;
}

export const users: UsersApiType = {
  addUser: (request: AddUserRequest): Promise<void> =>
    apiCreator
      .post<void>(
        `${API_URL.AUTH}/users/add`,
        request,
      )
      .then(({ data }) => data),
}
