import { Image } from '@mantine/core'
import { isNil } from 'ramda'
import { Link } from 'react-router-dom'
import styles from './OrderPositionsTableOrderId.module.css'
import amoCRMIconSrc from '../../../../../assets/img/amocrm-icon.png'
import metabaseIconSrc from '../../../../../assets/img/metabase-icon.png'

function getAmoCRMUrl(orderId: number): string {
  return `https://appscience.amocrm.ru/leads/detail/${orderId}`
}

function getMetabaseUrl(orderId: number): string {
  return `https://analytics.appscience.ru/public/dashboard/43791a6a-3809-4a7d-9ea8-98362f4d11ce?deal_id=${orderId}`
}

type OrderPositionsTableOrderIdProps = {
  orderId: number;
}

export function OrderPositionsTableOrderId({ orderId }: OrderPositionsTableOrderIdProps) {
  if (isNil(orderId)) {
    return null
  }

  return <div className={styles['container']}>
    {orderId}
    <Link
      to={{
        pathname: getAmoCRMUrl(orderId),
      }}
      target='_blank'
      className={styles['link']}
    >
      <Image
        src={amoCRMIconSrc}
        width={20}
        height={20}
        radius='md'
        alt='Amo CRM icon'
      />
    </Link>
    <Link
      to={{
        pathname: getMetabaseUrl(orderId),
      }}
      target='_blank'
      className={`${styles['link']} ${styles['link--moderation']}`}
    >
      <Image
        src={metabaseIconSrc}
        width={20}
        height={25}
        radius='md'
        alt='Metabase icon'
      />
    </Link>
  </div>
}
