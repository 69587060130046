import {DirectionType} from 'antd/lib/config-provider/context'
import {keys} from 'ramda'
import React, {useEffect} from 'react'
import {useThemeSwitcher} from 'react-css-theme-switcher'
import {ConfigProvider} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import useBodyClass from '../hooks/useBodyClass'
import {getRedirectConfig} from '../routes/getRedirectConfig'
import {selectDirection} from '../store/theme/selectors'
import {setDeviceMode} from '../store/theme/reducer'
import {verify} from '../utils/Logger'
import {AuthView} from './auth-view'
import ForgotPasswordForm from './auth-view/forgot-pass-form'
import {Switch} from 'react-router-dom'
import {ProposalView} from './proposal-view'
import {AddUserView} from './users/add'
import {withOldAppLayout} from '../page-layouts/old-layout/old-app-layout'
import ThemeLoading from '../components/loading/themeLoading'
import {TypedRedirect, TypedRoute} from '../routes/components'
import {useScreenSize} from '../hooks/useScreenSize/useScreenSize'
import { OrderPositionsView } from './OrdersView/OrderPositionsView'
import { withDefaultLayout } from '../page-layouts/DefaultLayout/DefaultLayout'
import { PaymentsView } from './PaymentsView/PaymentsView'
import { BonusesView } from './BonusesView/BonusesView'

export const Views = () => {
  const direction = useSelector(selectDirection)
  const dispatch = useDispatch()
  const _deviceType = useScreenSize()

  useEffect(() => {
    dispatch(setDeviceMode(_deviceType))
  }, [_deviceType, dispatch])

  useBodyClass(`dir-${direction}`)

  const { status } = useThemeSwitcher()
  const redirects = getRedirectConfig()

  return (
    <ConfigProvider direction={direction as DirectionType}>
      <div className='auth-container'>
        {status !== 'loaded' && <ThemeLoading />}
        <Switch>
          {keys(redirects).map(path => (
            <TypedRoute key={path} path={path} exact>
              <TypedRedirect {...verify(redirects[path])} />
            </TypedRoute>
          ))}
          <TypedRoute path='/auth/forgot' exact>
            <ForgotPasswordForm/>
          </TypedRoute>
          <TypedRoute path='/auth'>
            <AuthView/>
          </TypedRoute>

          <TypedRoute path='/proposal' exact>
            {withDefaultLayout(
              <ProposalView />,
              'Генератор КП',
            )}
          </TypedRoute>

          <TypedRoute path='/users/add' exact>
            {withOldAppLayout(<AddUserView/>)}
          </TypedRoute>

          <TypedRoute path='/moderation' exact>
            {withDefaultLayout(
              <OrderPositionsView />,
              'Модерация сделок',
            )}
          </TypedRoute>

          <TypedRoute path='/payments' exact>
            {withDefaultLayout(
              <PaymentsView />,
              'Оплаты',
            )}
          </TypedRoute>

          <TypedRoute path='/bonuses' exact>
            {withDefaultLayout(
              <BonusesView />,
              'Бонусы',
            )}
          </TypedRoute>

        </Switch>
      </div>
    </ConfigProvider>
  )
}
