import { auth } from './auth/auth'
import { casId } from './cas-id/cas-id'
import { proposal } from './proposal/proposal'
import { spreadsheet } from './spreadsheet/spreadsheet'
import { users } from './users/users'
import { vendors } from './vendors/vendors'

export function getApi() {
  return {
    users,
    auth,
    casId,
    proposal,
    spreadsheet,
    vendors,
  }
}

export type ARType<T extends (...args: unknown[]) => unknown> = Awaited<ReturnType<T>>