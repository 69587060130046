import React, { useCallback, useEffect, useMemo } from 'react'
import { AutoComplete, Card, Col, Input, Pagination, Row, Select, notification } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { ProductCard } from './product-card'
import { PAGINATION_PAGE_SIZE, useSearchContentHook } from './search.hook'
import { getApi } from '../../../../api/getApi'
import { selectAddToCartBtnDisable } from '../../../../store/cart/cart.selectors'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { ProductType } from '../../../../store/search/types'
import { useAutoCompleteDropdown } from './hooks/use-auto-complete-dropdown'
import { GetVendorsResponse } from '../../../../api/vendors/vendors'
import {ApiError} from '../../../../api/apiError/apiError'

const { Search } = Input
const { Option } = Select

export const SearchContent: React.FC = () => {
  const {
    searchLoading,
    filteredWord,
    searched,
    searchPaginationCurrent,
    totalSize,
    isDropdownVisible,
    searchOptions,
    requestSearchWord,
    analogsCasId,
    analogsLoading,
    currentShowAnalogsItems,
    paginationAnalogsCurrent,
    vendorsList,
    searchPaginationTotalProductCount,
    totalAnalogsPagination,
    isReverseSorting,
    sortingProp,
    searchResultProducts,

    setIsReverseSorting,
    setSortingProp,
    searchPaginationHandler,
    setFilteredWord,
    setSearchPaginationCurrent,
    onSearchHandler,
    addToCartHandler,
    onSearchInput,
    setDropdownVisible,
    onChooseSearchLabelHandler,
    setPaginatioAnalogsCurrent,
    filteredAnalogsArray,
    setVendorsList,
    setSearchVendorsList,
  } = useSearchContentHook()

  const hideDropdown = useCallback(() => {
    setDropdownVisible(false)
  }, [setDropdownVisible])
  const {
    maxDropdownHeight,
    autoCompleteRef,
  } = useAutoCompleteDropdown({
    hideDropdown,
  })

  useEffect(() => {
    let isMounted = true

    getApi()
      .vendors
      .get()
      .then((response: GetVendorsResponse) => {
        const { vendors } = response
        if (isMounted) {
          setVendorsList(vendors)
        }
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении списка вендоров',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })

    return () => {
      isMounted = false
    }
  }, [setVendorsList])

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!requestSearchWord.trim() && !isDropdownVisible && e.keyCode === 40) {
      onSearchInput(requestSearchWord)
    }
  }

  const addToCartBtnDisable = useSelector(selectAddToCartBtnDisable)
  // TODO fix backend error
  const searchResultProductsSlice: Array<ProductType> = useMemo(
    () => searchResultProducts.slice(0, PAGINATION_PAGE_SIZE),
    [searchResultProducts],
  )

  const onSearchInputFocus = useCallback(() => {
    if (requestSearchWord.trim()) {
      onSearchInput(requestSearchWord)
    }
  }, [requestSearchWord, onSearchInput])

  return (
    <>
      <Card
        bordered
      >
        <div className='search-section'>
          <Row
            gutter={16}
            className='flex-row-reverse'
          >
            <Col
              span={24}
              md={17}
              className='gutter-row mb-2'
            >
              <AutoComplete
                ref={autoCompleteRef}
                options={searchOptions}
                onSelect={onChooseSearchLabelHandler}
                value={requestSearchWord}
                style={{ width: '100%' }}
                open={isDropdownVisible}
                listHeight={maxDropdownHeight}
                autoFocus={false}
                placement='bottomLeft'
                className='auto-complete-search'
              >
                <Search
                  style={{ position: 'relative' }}
                  placeholder='Введите любое слово..'
                  enterButton='Найти'
                  disabled={searchLoading}
                  className='search-form'
                  autoFocus={false}
                  onFocus={onSearchInputFocus}
                  onBlur={() => setDropdownVisible(false)}
                  onSearch={() => onSearchHandler()}
                  onKeyDown={e => onKeyDownHandler(e)}
                  onChange={e => onSearchInput(e.target.value)}
                />
              </AutoComplete>
            </Col>
            <Col
              span={24}
              md={7}
            >
              <Select
                mode='multiple'
                allowClear
                onChange={(value: string[]) => {
                  setSearchVendorsList(value)
                }}
                style={{ width: '100%' }}
                placeholder='Выберите вендоры'
              >
                {vendorsList.map((item, key) => (
                  <Option
                    key={key}
                    value={item}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        {searched && (
          <div className='filter-section mt-3'>
            <Row align='middle'>
              <Col span={6}>
                {totalSize
                  ? `Найдено ${totalSize} позиций`
                  : 'Ничего не найдено'
                }
              </Col>
              <Col span={18}>
                <Input
                  placeholder='Фильтр по любому полю...'
                  value={filteredWord}
                  prefix={<SearchOutlined className='mr-0' />}
                  onChange={e => {
                    setFilteredWord(e.target.value)
                    setSearchPaginationCurrent(1)
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {(searchResultProductsSlice.length > 0
          || searchLoading
          || searchPaginationTotalProductCount > PAGINATION_PAGE_SIZE
        ) && (
          <div className='product-list-section mt-3'>
            <Row>
              <Col span={24}>
                {searchResultProductsSlice
                  .map((item, key) => (
                    <ProductCard
                      {...item}
                      key={key}
                      isDisabledBtn={addToCartBtnDisable}
                      addToCart={() => addToCartHandler(item)}
                    />
                  ))
                }
                {searchLoading && (
                  <div className='d-flex justify-content-center align-items-center'>
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col
                span={24}
              >
                {searchPaginationTotalProductCount > PAGINATION_PAGE_SIZE && (
                  <div
                    className='d-flex justify-content-center pt-4 pb-4'
                  >
                    <Pagination
                      disabled={searchLoading}
                      pageSize={PAGINATION_PAGE_SIZE}
                      total={searchPaginationTotalProductCount}
                      current={searchPaginationCurrent}
                      onChange={value => searchPaginationHandler(value)}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Card>

      {analogsLoading && (
        <div className='d-flex justify-content-center align-items-center'>
          <LoadingOutlined style={{ fontSize: 24 }} />
        </div>
      )}
      {(totalAnalogsPagination && !analogsLoading) ? (
        <>
          <div className='d-flex justify-content-between pt-3 pb-3'>
            <h4>Аналоги (CAS {analogsCasId})</h4>
            <div className='analogs-sorting-block d-flex justify-content-between align-items-center'>
              {sortingProp && (
                <div className='icon-block pr-4'>
                  <ArrowDownOutlined
                    className={`${isReverseSorting ? '' : 'text-white p-2 bg-primary rounded-1 mr-2'}`}
                    style={{ cursor: 'pointer', borderRadius: '.3rem' }}
                    onClick={() => setIsReverseSorting(false)}
                  />
                  <ArrowUpOutlined
                    className={`${isReverseSorting ? 'text-white p-2 bg-primary rnd-1  ml-2' : ''}`}
                    style={{ cursor: 'pointer', borderRadius: '.3rem' }}
                    onClick={() => setIsReverseSorting(true)}
                  />
                </div>
              )}

              <div className='sorting-props-list'>
                <Select
                  style={{ width: 180 }}
                  defaultValue=''
                  value={sortingProp}
                  onChange={(value: AnyType) => setSortingProp(value)}
                >
                  <Option value=''> </Option>
                  <Option value='package_size'>package_size</Option>
                  <Option value='vendor'>brand</Option>
                  <Option value='partnership_type'>partnership_type</Option>
                </Select>
              </div>
            </div>
          </div>
          <Card bordered>
            {filteredAnalogsArray()
              .map((item, key) => (
                <ProductCard
                  {...item}
                  isDisabledBtn={addToCartBtnDisable}
                  key={key}
                  addToCart={() => addToCartHandler(item)}
                />
              ))
            }
            {currentShowAnalogsItems < totalAnalogsPagination && (
              <div className='d-flex justify-content-center pt-4 pb-4'>
                <Pagination
                  total={totalAnalogsPagination}
                  current={paginationAnalogsCurrent}
                  pageSize={currentShowAnalogsItems}
                  onChange={value => {
                    setPaginatioAnalogsCurrent(value)
                  }}
                  showSizeChanger={false}
                />
              </div>
            )}
          </Card>
        </>
      ) : undefined}
    </>
  )
}
