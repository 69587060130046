import { Tooltip, Typography } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import { CountryEnum } from '../../../../../../utils/country'
import CountryFlagIcon from '../../../CountryFlagIcon'
import cn from 'classnames'
import styles from './top-product-properties.module.css'

const { Text } = Typography

type TopProductPropertiesProps = {
  brand: string;
  vendor: string;
  countryOfPurchase: string;
  isAvailable: boolean;
  updateTimeTooltipMessage: string;
}

export function TopProductProperties({
  brand,
  vendor,
  countryOfPurchase,
  isAvailable,
  updateTimeTooltipMessage,
}: TopProductPropertiesProps) {
  return (
    <>
      <Text
        className='text-info font-weight-bolder product-top-label'
      >
        {vendor}
      </Text>
      {brand && brand !== vendor && (
        <Text
          className='text-info font-weight-bolder product-top-label text-nowrap ml-3'
        >
          {brand}
        </Text>
      )}
      {countryOfPurchase && (
        <div
          className={cn(
            'ml-3',
            styles['country-flag-container'],
          )}
        >
          <CountryFlagIcon
            countryCode={countryOfPurchase as CountryEnum}
          />
        </div>
      )}
      <Text
        className={cn(
          'font-weight-bold rounded ml-3 product-top-label',
          { 'text-success': isAvailable },
          { 'text-gray-light opacity-0-5': !isAvailable },
        )}
      >
        {isAvailable
          ? 'Available'
          : 'Non-available'
        }
      </Text>
      <Tooltip
        placement='bottomLeft'
        title={updateTimeTooltipMessage}
        style={{ minWidth: 400 }}
        className='ml-3'
      >
        <FieldTimeOutlined
          className={styles['update-time-icon']}
        />
      </Tooltip>
    </>
  )
}
