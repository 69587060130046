import FlagIcons from 'country-flag-icons/react/3x2'
import { useMemo } from 'react'
import { CountryEnum, getCountryIcon } from '../../../../utils/country'

type CountryFlagIconProps = {
  countryCode: CountryEnum,
}

export const CountryFlagIcon: React.FC<CountryFlagIconProps> = ({
  countryCode,
}) => {
  const { countryFlagIconCode, title } = useMemo(() => getCountryIcon(countryCode), [countryCode])
  const FlagIcon = FlagIcons[countryFlagIconCode]

  return <FlagIcon title={title} />
}
