import {MutableRefObject} from 'react'
import { SnipState } from '../hooks/useSnip'
import { ReactSnipCallbacks } from '../MultilineEllipsis'
import {snipTextCSS, snipTextJS} from './'

export const snipText = (ref: MutableRefObject<HTMLElement>, state: MutableRefObject<SnipState>, callbacks?: ReactSnipCallbacks): void => {
  const { options, element } = state.current

  if (options.method === 'css') {
    snipTextCSS(ref, state)
    return
  }

  if (options.method === 'js') {
    snipTextJS(ref, state, callbacks)
    element.prevWidth = ref.current.clientWidth
    element.prevHeight = ref.current.clientHeight
  }
}
