export enum ProviderStatusEnum {
  Ok = 'ok',
  Freezed = 'freezed',
  Blocked = 'blocked',
}

export type ProductType = {
  id: string;
  catalogue_id: string;
  vendor: string;
  partner_url: string;
  info_from_client: string;
  name: string;
  short_description: string;
  cas_id: string;
  hs_id: string;
  price: number;
  price_currency: string;
  batch_id: string;
  availability: string;
  number_per_order: string;
  sales_restrictions: string
  sales_restrictions_detailed: string;
  package_type: string;
  package_size: string;
  molecular_formula: string;
  detailed_description: string;
  un_id: string;
  shipping_group_id: string;
  shipping_conditions: string;
  sds: string;
  storage_description: string;
  validity: string;
  parse_time: string;
  handle_time: string;
  input_by_handle?: boolean;
  found_by: string;
  partnership_type: string;
  excel_comment: string;
  hazmat: boolean;
  country_of_purchase: string;
  availability_type: string;
  availability_flag: boolean;
  brand: string;
  vendor_availability: {
    providers_statuses: Record<string, ProviderStatusEnum>;
    availability_flag: boolean;
  };
  need_analogues: boolean;
}
