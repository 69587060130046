import { Button, LoadingOverlay, Textarea } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import OrdersService, { GetTwinUserItemsResponse } from '../../../../../api/orders/orders'
import { setOrderPositions } from '../../../../../store/order-positions-table/reducer'
import { OrderPosition, mapTwinUserItemResponseToOrderPosition } from '../../../OrderPositions.utils'
import { LinesEllipsisWithTooltip } from '../../LinesEllipsisWithTooltip/LinesEllipsisWithTooltip'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import {ApiError} from '../../../../../api/apiError/apiError'

type OrdersPositionsTableCommentProps = {
  twinUserItemId: number;
  comment: string;
}

export function OrdersPositionsTableComment({ twinUserItemId, comment = '' }: OrdersPositionsTableCommentProps) {
  const [isText, setIsText] = useState(true)
  const [inputValue, setInputValue] = useState(comment)
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onChange = useCallback((newComment: string) => {
    setLoading(true)

    OrdersService
      .saveSalesComments(twinUserItemId, newComment)
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при изменении комментария',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .then(() => OrdersService
        .getTwinUserItems()
        .then((response: GetTwinUserItemsResponse) => {
          const orderPositions: Array<OrderPosition> = (response?.items ?? [])
            ?.map(mapTwinUserItemResponseToOrderPosition) ?? []
          dispatch(setOrderPositions(orderPositions))
        })
        .catch((error: ApiError) => {
          notification.error({
            message: 'Ошибка при получении данных о позициях',
            description: error.getRuText?.() || error.message,
            duration: 5,
          })
          Sentry.captureException(error)
        }),
      )
      .finally(() => {
        setLoading(false)
      })
  }, [twinUserItemId, dispatch])

  const onTextClick = useCallback(() => {
    setInputValue(comment)
    setIsText(false)
  }, [setInputValue, comment])

  const onApprove = useCallback(() => {
    setIsText(true)
    onChange(inputValue)
  }, [onChange, inputValue])

  const onCancel = useCallback(() => {
    setIsText(true)
  }, [])

  if (isLoading) {
    return (
      <div style={{ position: 'relative', height: 60 }}>
        <LoadingOverlay visible={isLoading} />
      </div>
    )
  }

  if (isText) {
    return (
      <div
        className='comment'
        onClick={onTextClick}
      >
        <LinesEllipsisWithTooltip
          text={comment || '-'}
          maxLines={3}
        />
      </div>
    )
  }

  return (
    <div className='comment'>
      <Textarea
        value={inputValue}
        placeholder='Введите текст комментария'
        minRows={3}
        maxRows={3}
        onChange={event => setInputValue(event.currentTarget.value)}
      />
      <div className='flex pt-1 justify-between'>
        <Button
          compact
          onClick={onApprove}
        >
          Сохранить
        </Button>
        <Button
          compact
          variant='light'
          className='bg-transparent mr-2'
          disabled={false}
          onClick={onCancel}
        >
          Отмена
        </Button>
      </div>
    </div>
  )
}