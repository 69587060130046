import React from 'react'
import {Layout} from 'antd'
import {useCheckAuth} from '../../hooks/useCheckAuth'
import {OldAppHeader} from './old-app-header'

export function withOldAppLayout(
  children: Array<React.ReactNode>|React.ReactNode,
  specificStyleClassname = '',
  headerTitle = '',
) {
  return <OldAppLayout
    specificStyleClassname={specificStyleClassname}
    headerTitle={headerTitle}
  >
    {children}
  </OldAppLayout>
}

type OldAppLayoutProps = {
  children: Array<React.ReactNode>|React.ReactNode;
  specificStyleClassname: string;
  headerTitle?: string;
}

const OldAppLayout: React.FC<OldAppLayoutProps> = props => {
  useCheckAuth()

  return (
    <Layout className={`default-app-layout ${props.specificStyleClassname}`}>
      <OldAppHeader title={props.headerTitle} />
      <Layout.Content className='main'>
        {props.children}
      </Layout.Content>
    </Layout>
  )
}
