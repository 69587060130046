import React, {useState} from 'react'
import { DefaultTooltip } from '../DefaultTooltip/DefaultTooltip'
import { MultilineEllipsis } from '../MultilineEllipsis/MultilineEllipsis'

const ELLIPSIS_CHAR = '...'

interface LinesEllipsisWithTooltipProps {
  text: React.ReactNode,
  maxLines?: number,
}

export function LinesEllipsisWithTooltip({
  text,
  maxLines = 1,
}: LinesEllipsisWithTooltipProps) {
  const [disabledTip, setDisabledTip] = useState(true)

  return (
    <DefaultTooltip
      label={text}
      withArrow
      disabled={disabledTip}
    >
      <MultilineEllipsis
        lines={maxLines + 1}
        method='js'
        ellipsis={ELLIPSIS_CHAR}
        callbacks={{
          onJsClamp: clamped => setDisabledTip(!clamped),
        }}
      >
        {text}
      </MultilineEllipsis>
    </DefaultTooltip>
  )
}
