import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'

export type GetVendorsResponse = {
  vendors: string[];
}

type VendorApiType = {
  get: () => Promise<GetVendorsResponse>;
  getForHandleInput: () => Promise<string>;
}

export const vendors: VendorApiType = {
  get: () =>
    apiCreator.get(
      `${API_URL.PARTNER_PRODUCTS}/vendors`,
    )
      .then(({ data }) => data),
  getForHandleInput: () =>
    apiCreator.get(
      `${API_URL.PROPOSALS}/vendors`,
    )
      .then(({ data }) => data),
}
