import { createAsyncThunk } from '@reduxjs/toolkit'
import { setImportOrGenerateLoading } from '../../generate-table/reducer'
import { RegenerateProposalSpreadsheetAsyncResponse } from '../../../api/spreadsheet/spreadsheet'
import { getApi } from '../../../api/getApi'
import { getIdFromGoogleSpreadsheetUrl } from '../../../views/proposal-view/content/ProposalGeneration/ProposalGeneration.utils'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'

export const regenerateSpreadsheetUrlAction = createAsyncThunk<
  string,
  {
    orderNumber: string;
    linkToTable: string;
  }
>(
  'cart/regenerateSpreadsheetUrl',
  async ({ orderNumber, linkToTable }, { dispatch }) => {
    try {
      dispatch(setImportOrGenerateLoading(true))

      const { job_id: jobId }: RegenerateProposalSpreadsheetAsyncResponse =
        await getApi()
          .spreadsheet
          .regenerateProposalSpreadsheetAsync({
            id: orderNumber,
            sourceSpreadsheetId: getIdFromGoogleSpreadsheetUrl(linkToTable),
          })

      return await getApi()
        .proposal
        .getJobResult<string>({
          jobId,
        })
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при перегенерации таблицы',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)

      throw error
    }
  },
)
