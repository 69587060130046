import { verify } from '@appscience/utils'
import { MutableRefObject } from 'react'
// import {verify} from '../../../utils/Logger'
// import {ReactSnipCallbacks} from '../components/MultilineEllipsis'
import {SnipState} from '../hooks/useSnip'
import { ReactSnipCallbacks } from '../MultilineEllipsis'
import {elementLines} from '../utils'

export const snipTextJS = (ref: MutableRefObject<HTMLElement>, state: MutableRefObject<SnipState>, callbacks?: ReactSnipCallbacks): void => {
  const { options, element } = state.current

  ref.current.style.display = ''
  ref.current.style.webkitLineClamp = ''
  ref.current.style.webkitBoxOrient = ''
  ref.current.style.overflow = ''
  ref.current.textContent = verify(element.fullText)

  if (options.lines <= 0 || elementLines(ref.current) <= options.lines) {
    callbacks && callbacks.onJsClamp(false)
    return
  }

  const snipProgress = {
    unprocessed: element.fullText,
    processed: '',
  }

  const separators = options.midWord ? ['. ', ', ', ' ', ''] : ['. ', ', ', ' ']

  separators.forEach(separator => {
    for (const chunk of verify(snipProgress.unprocessed).split(separator)) {
      ref.current.textContent = `${snipProgress.processed}${chunk}${separator}${options.ellipsis}`

      if (elementLines(ref.current) > options.lines) {
        snipProgress.unprocessed = chunk
        break
      }

      snipProgress.processed = `${snipProgress.processed}${chunk}${separator}`
    }
  })

  ref.current.textContent = `${snipProgress.processed.trim()}${options.ellipsis}`
  callbacks && callbacks.onJsClamp(true)
}
