import { ActionIcon, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { NavItem } from '../../DefaultLayout'
import cn from 'classnames'
import styles from './DefaultNavItem.module.css'

type DefaultNavItemContentProps = NavItem

function DefaultNavItemContent({
  title,
  icon: Icon,
  isActive = false,
  isDisabled = false,
}: DefaultNavItemContentProps) {
  return (
    <ActionIcon
      className={cn(
        styles['root'],
        { [styles['root--disabled']]: isDisabled },
      )}
      radius={0}
      color={isActive ? 'blue' : 'gray'}
    >
      <Icon className='w-6 h-6 shrink-0 ml-3' />
      <Text
        className={cn(
          'text-[16px] font-semibold ml-2 pr-3',
          styles['text'],
        )}
      >
        {title}
      </Text>
    </ActionIcon>
  )
}

type DefaultNavItemProps = NavItem

export function DefaultNavItem(props: DefaultNavItemProps) {
  if (props.isDisabled) {
    return (
      <DefaultNavItemContent
        {...props}
      />
    )
  }

  return (
    <Link
      to={props.link}
    >
      <DefaultNavItemContent
        {...props}
      />
    </Link>
  )
}
