import { AxiosResponse } from 'axios'
import axiosInstance from '../apiCreator'
import moment from 'moment'
import { API_URL } from '../../constants/environment'

type BooleanAsString = 'true' | 'false'

export type GetMetabaseEmbeddingUrlRequest = {
  payload: {
    resource: {
      dashboard: number;
    },
    params: {
      user_id: string;
      month: string;
    },
  },
  options: {
    bordered: BooleanAsString;
    titled: BooleanAsString;
  },
}
export type GetMetabaseEmbeddingUrlResponse = AxiosResponse<string>

type MetabaseEmbeddingServiceType = {
  getUrl: (amoUserId: string, date: moment.Moment) => Promise<GetMetabaseEmbeddingUrlResponse>;
}

const MetabaseEmbeddingService: MetabaseEmbeddingServiceType = {
  getUrl: (amoUserId: string, date: moment.Moment) =>
    axiosInstance.post<string, GetMetabaseEmbeddingUrlResponse, GetMetabaseEmbeddingUrlRequest>(
      `${API_URL.METABASE_EMBEDDING}/get-url`,
      {
        payload: {
          resource: {
            dashboard: 99,
          },
          params: {
            user_id: amoUserId,
            month: date?.format('YYYY-MM'),
          },
        },
        options: {
          bordered: 'false',
          titled: 'false',
        },
      },
    ),
}

export default MetabaseEmbeddingService
