import React from 'react'
import {Card, Col, Row} from 'antd'
import {useSelector} from 'react-redux'
import {TypedRoute} from '../../routes/components'
import {selectTheme} from '../../store/theme/selectors'
import {LoginForm} from './login-form'
import Loading from '../../components/loading/loading'
import {useThemeSwitcher} from 'react-css-theme-switcher'
import {Switch} from 'react-router-dom'

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const AuthView: React.FC = () => {
  const theme = useSelector(selectTheme)
  const {status} = useThemeSwitcher()
  const baseRoute = '/auth'


  const toggleAuthMode = () => {
    //setAuthMode(authMode)
  }

  if (status === 'loading') return <Loading cover='page' />

  return (
    <div className='h-100' style={backgroundStyle}>
      <div className='container d-flex flex-column justify-content-center h-100'>
        <Row justify='center'>
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className='my-4'>
                <div className='text-center'>
                  <img className='img-fluid img-logo' src={`/img/${theme === 'light' ? 'logo-white.svg' : 'logo-white.svg'}`} alt='' />
                  <Switch>
                    <TypedRoute path={`${baseRoute}/register`}>
                      <p>Have account? <a href='/auth/login'>Sign In</a></p>
                    </TypedRoute>
                  </Switch>
                </div>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Switch>
                      <TypedRoute path={`${baseRoute}/login`}>
                        <LoginForm toggleAuthMode={toggleAuthMode}/>
                      </TypedRoute>
                    </Switch>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
