import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'
import { GetPartnerProductsResponse } from '../search/search'

type GetProductByIdApi = {
  get: (id: string) => Promise<GetPartnerProductsResponse>,
}

export const getProductById: GetProductByIdApi = {
  get: (id: string): Promise<GetPartnerProductsResponse> =>
    apiCreator.get(
      `${API_URL.PARTNER_PRODUCTS}/request`,
      {
        params: {
          id,
        },
      },
    )
      .then(({ data }) => data),
}
