import { isNumber } from '@appscience/utils'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage/useLocalStorage'
import { resetPaginationIndex } from '../../../../../store/order-positions-table/reducer'
import { TableOrderPosition } from '../OrderPositionsTable.type'

export type OrdersPositionsTableInitialData = {
  paginationPage: number;
  paginationSize: number;
}

export function useOrderPositionsTableInitialData(tableOrderPositions: Array<TableOrderPosition>): OrdersPositionsTableInitialData {
  const {getStorageValue} = useLocalStorage('orderPositionsStage')

  const initialData = useMemo(() => {
    const rawPaginationPage = getStorageValue('paginationPage')
    const rawPaginationSize = getStorageValue('paginationSize')

    const paginationPage = rawPaginationPage && isNumber(rawPaginationPage) ? Number(rawPaginationPage) : 0
    const paginationSize = rawPaginationSize && isNumber(rawPaginationSize) ? Number(rawPaginationSize) : 25

    const maxPaginationPage: number = Math.floor((tableOrderPositions.length - 1) / paginationSize)

    return {
      paginationPage: maxPaginationPage < paginationPage ? 0 : paginationPage,
      paginationSize,
    }
  }, [getStorageValue, tableOrderPositions.length])

  // Set paginationIndex to 0 for fixing bug with invalid exceeding paginationIndex when changing statusType
  const dispatch = useDispatch()
  useEffect(() => {
    if (!initialData.paginationPage) {
      dispatch(resetPaginationIndex())
    }
  }, [dispatch, initialData.paginationPage])

  return initialData
}
