import { GetTwinUserItemsResponse } from './orders'

export const TWIN_USER_ITEMS_RESPONSE_MOCK: GetTwinUserItemsResponse = {
  error_reason: 'NoError',
  result: 'success',
  items: [{
    client_name: 'Нанолек',
    deadline_date: '2022.07.25',
    deal_id: 13395565,
    delay_reason: 'Форс-мажор',
    expected_delivery_date: '2022.07.24',
    id: 1,
    name: 'Метанол Scharlayu Art. ME0315 1 000 МЛ',
    prt_comments: '',
    sales_comments: 'sales comment 1',
    sd_comments: 'sd comment 1 sd comment 1',
    status: 'arrived_to_hub',
    status_description: 'Прибыло в хаб',
    last_status_date: '2022.07.07',
  }, {
    client_name: 'Нанолек 2',
    deadline_date: '2022.07.30',
    deal_id: 13395566,
    delay_reason: 'Форс-мажор',
    expected_delivery_date: '2022.07.27',
    id: 2,
    name: 'Mannitol Meets ACS Specifications GR ACS 3kg',
    prt_comments: 'prt comment 2 prt comment 2 prt comment 2',
    sales_comments: 'Так получилось',
    sd_comments: 'sd comment 2',
    status: 'on_border',
    status_description: 'Прибыло на таможню',
    last_status_date: '2022.07.06',
  }, {
    client_name: 'Нанолек',
    deadline_date: '2022.08.03',
    deal_id: 13395566,
    delay_reason: 'Форс-мажор',
    expected_delivery_date: '2022.08.02',
    id: 3,
    name: 'Метанол Scharlayu Art. ME0315 1 000 МЛ 2',
    prt_comments: 'prt comment 3 prt comment 3 prt comment 3 prt comment 3 prt comment 3 prt comment 3',
    sales_comments: 'Так получилось 2',
    sd_comments: '',
    status: 'on_border',
    status_description: 'Прибыло на таможню',
    last_status_date: '2022.07.03',
  }],
}
