import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getApi } from '../../../../api/getApi'
import { selectCartItems, selectIsCartLoading } from '../../../../store/cart/cart.selectors'
import {
  setAddToBtnDisable,
  setCart,
  setEditedItemIndex,
  setIsCartLoading,
  setOpenHandleEditor,
  setProductsListAction,
} from '../../../../store/cart/cart.reducer'
import {
  setBillPdfLinkAction,
  setDisabledOrderFormAction,
  setDocLinkAction,
  setGoogleSheetsLinkAction,
  setLinkToTableAction,
  setOrderNumberAction,
  setPdfLinkAction,
  setPipeline,
  setRubGoogleSheetsLinkAction,
  setUsdGoogleSheetsLinkAction,
} from '../../../../store/generate-table/reducer'
import { notification } from 'antd'
import { ProductType } from '../../../../store/search/types'
import { GetProductAvailabilityResponse, availabilityApi } from '../../../../api/availability'
import { GetProposalResponse, PipelineEnum } from '../../../../api/proposal/proposal'
import * as Sentry from '@sentry/react'
import { ApiError } from '../../../../api/apiError/apiError'
import { isNumeric } from '../../../../utils/number'
import { ApiErrorCodeEnum } from '../../../../api/apiError/apiErrorCode.enum'
import { useLocalStorage } from '../../../../hooks/useLocalStorage/useLocalStorage'
import { useCartStorage } from './cart.storage'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function useCart() {
  const dispatch = useDispatch()
  const cartItems = useSelector(selectCartItems)
  const isCartLoading = useSelector(selectIsCartLoading)
  const [isVisibleLoader, _setIsVisibleLoader] = useState<boolean>(true)
  const query = useQuery()
  const orderNumber = query.get('number') || ''
  const history = useHistory()
  const { getStorageValue, setValueToStorage, removeValueFromStorage } = useLocalStorage('proposalGenerationStage')
  const { getSavedCart, saveCart, clearSavedCart } = useCartStorage()

  const setIsVisibleLoader = () => {
    _setIsVisibleLoader(isVisibleLoader)
  }

  function clearSavedOrder() {
    dispatch(setCart([]))
    clearSavedCart()
    setValueToStorage('orderNumber', orderNumber)
    removeValueFromStorage('linkToTable')
    removeValueFromStorage('spreadsheet')
    removeValueFromStorage('spreadsheetRub')
    removeValueFromStorage('pdf')
    removeValueFromStorage('doc')
    removeValueFromStorage('bill')
    removeValueFromStorage('specificationPdf')
    removeValueFromStorage('specificationDoc')
  }

  const setInitialCartStatus = () => {
    dispatch(setIsCartLoading(false))
    setIsVisibleLoader()

    if (orderNumber !== getStorageValue('orderNumber')) {
      clearSavedOrder()
      return
    }

    setCart(getSavedCart())
  }

  const initTableInfoState = () => {
    if (orderNumber) {
      dispatch(setDisabledOrderFormAction(true))
      dispatch(setOrderNumberAction(orderNumber))
    }

    const linkToTable = getStorageValue('linkToTable')
    const googleSheetsLink = getStorageValue('spreadsheet')
    const googleSheetsUsdLink = getStorageValue('spreadsheet')
    const googleSheetsRubLink = getStorageValue('spreadsheetRub')
    const pdfLink = getStorageValue('pdf')
    const docLink = getStorageValue('doc')
    const billLink = getStorageValue('bill')

    linkToTable && dispatch(setLinkToTableAction(linkToTable))
    googleSheetsLink && dispatch(setGoogleSheetsLinkAction(googleSheetsLink))
    googleSheetsRubLink && dispatch(setRubGoogleSheetsLinkAction(googleSheetsRubLink))
    googleSheetsUsdLink && dispatch(setUsdGoogleSheetsLinkAction(googleSheetsUsdLink))
    pdfLink && dispatch(setPdfLinkAction(pdfLink))
    docLink && dispatch(setDocLinkAction(docLink))
    billLink && dispatch(setBillPdfLinkAction(billLink))
  }

  const initState = () => {
    const urlNumber = query.get('number')

    if (!urlNumber) {
      setInitialCartStatus()
      initTableInfoState()
      return
    }

    if (!isNumeric(urlNumber)) {
      setDataInStore(null)
      dispatch(setOrderNumberAction(''))
      history.push('/')
      notification.warning({
        message: 'Номер сделки должен состоять из цифр (0-9)',
        duration: 5,
      })
      return
    }

    dispatch(setDisabledOrderFormAction(true))
    dispatch(setIsCartLoading(true))
    setIsVisibleLoader()

    getApi()
      .proposal
      .getProposalData({
        id: urlNumber,
      })
      .then((response: GetProposalResponse) => {
        dispatch(setOrderNumberAction(urlNumber))
        setDataInStore(response)
      })
      .catch((error: ApiError) => {
        setDataInStore(null)
        dispatch(setOrderNumberAction(''))
        history.push('/')
        if (error instanceof ApiError && error?.getCode() === ApiErrorCodeEnum.get_amo_deal) {
          notification.error({
            message: `Ошибка при получении данных КП`,
            description: `Сделка с номером ${urlNumber} не найдена`,
            duration: 5,
          })
          return
        }

        notification.error({
          message: 'Ошибка при получении данных КП',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })

        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setIsCartLoading(false))
      })
  }

  const setDataInStore = (data: GetProposalResponse | null) => {
    const {
      items = [],
      spreadsheet_url = '',
      proposal_pdf_url = '',
      proposal_doc_url = '',
      bill_pdf_url = '',
      pipeline = PipelineEnum.Supply,
    } = data ?? {}

    saveCart(items)
    dispatch(setProductsListAction(items))
    dispatch(setLinkToTableAction(spreadsheet_url))
    dispatch(setPdfLinkAction(proposal_pdf_url))
    dispatch(setDocLinkAction(proposal_doc_url))
    dispatch(setBillPdfLinkAction(bill_pdf_url))
    dispatch(setPipeline(pipeline))
  }

  const onCountHandler = (value: number, itemIndex: number, availability_type?: string) => {
    if (value === 0) return

    const cart = getSavedCart()
    if (value === -1) {
      cart.splice(itemIndex, 1)
    }
    else {
      cart[itemIndex].count = value
    }

    saveCart(cart)
    dispatch(setProductsListAction([...cart]))

    if (value > 0 && availability_type === 'ON-DEMAND') {
      availabilityRequestHandler(cart[itemIndex])
    }
  }

  const onToggleNeedAnaloguesHandler = (value: boolean, itemIndex: number) => {
    const cart = getSavedCart()

    cart[itemIndex].need_analogues = value

    saveCart(cart)
    dispatch(setProductsListAction([...cart]))
  }

  const onSelectAllNeedAnaloguesHandler = () => {
    const cart = getSavedCart()

    cart.forEach(item => {
      item.need_analogues = true
    })

    saveCart(cart)
    dispatch(setProductsListAction([...cart]))
  }

  const availabilityRequestHandler = (object: ProductType) => {
    dispatch(setAddToBtnDisable(true))
    const id = object.id
    const cart = getSavedCart()
    const count = cart.filter(item => item.id === id)[0].count

    availabilityApi
      .getProductAvailability(id, count)
      .then(({ available }: GetProductAvailabilityResponse) => {
        cart.forEach((cartItem, index) => {
          if (cartItem.id === id) {
            cart[index].availability_flag = available
          }
        })
        saveCart(cart)
        dispatch(setProductsListAction([...cart]))
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении данных о доступности',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        dispatch(setAddToBtnDisable(false))
      })
  }

  const onStartEditHandler = (index: number) => {
    dispatch(setOpenHandleEditor(true))
    dispatch(setEditedItemIndex(index))
  }

  return {
    cartItems,
    isCartLoading,
    isVisibleLoader,
    setInitialCartStatus,
    onCountHandler,
    onToggleNeedAnaloguesHandler,
    onSelectAllNeedAnaloguesHandler,
    initTableInfoState,
    initState,
    setIsVisibleLoader,
    onStartEditHandler,
  }
}
