import {ComponentPropsWithRef} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {AppRoute} from './types'

export {
  TypedRoute,
  TypedRedirect,
}

interface TypedRouteProps extends ComponentPropsWithRef<typeof Route> {
  path: AppRoute,
}

function TypedRoute(props: TypedRouteProps) {
  return <Route {...props} />
}


export interface TypedRedirectProps extends ComponentPropsWithRef<typeof Redirect> {
  to: AppRoute,
  from?: AppRoute,
}

function TypedRedirect(props: TypedRedirectProps) {
  return <Redirect {...props} />
}