import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectGenerate = (state: RootState) => state.generate

export const selectGoogleSheetsLink = createSelector(selectGenerate, state => state.googleSheetsLink)
export const selectRubGoogleSheetsLink = createSelector(selectGenerate, state => state.rubGoogleSheetsLink)
export const selectUsdGoogleSheetsLink = createSelector(selectGenerate, state => state.usdGoogleSheetsLink)
export const selectPdfLink = createSelector(selectGenerate, state => state.pdfLink)
export const selectDocLink = createSelector(selectGenerate, state => state.docLink)
export const selectBillPdfLink = createSelector(selectGenerate, state => state.billPdfLink)
export const selectImportTablePdfLink = createSelector(selectGenerate, state => state.importTableLink)
export const selectSpecificationPdfLink = createSelector(selectGenerate, state => state.specificationPdfLink)
export const selectSpecificationDocLink = createSelector(selectGenerate, state => state.specificationDocLink)
export const selectOrderNumber = createSelector(selectGenerate, state => state.orderNumber)
export const selectDisabledOrderForm = createSelector(selectGenerate, state => state.disabledOrderForm)
export const selectLinkToTable = createSelector(selectGenerate, state => state.linkToTable)
export const selectImportOrGenerateLoading = createSelector(selectGenerate, state => state.importOrGenerateLoading)
export const selectPipeline = createSelector(selectGenerate, state => state.pipeline)
