import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectCart = (state: RootState) => state.cart

export const selectCartItems = createSelector(selectCart, state => state.cartItems)
export const selectEditedItem = createSelector(selectCart, state => state.editedItem)
export const selectOpenHandleEditor = createSelector(selectCart, state => state.openHandleEditor)
export const selectAddToCartBtnDisable = createSelector(selectCart, state => state.addToCartBtnDisable)
export const selectIsCartLoading = createSelector(selectCart, state => state.isLoading)
