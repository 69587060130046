import {DataTable, DataTableColumnInfo, DataTableSearchFn} from '@appscience/data-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '../../../../hooks/useLocalStorage/useLocalStorage'
import { selectTheme } from '../../../../store/theme/selectors'
import { ThemeColorEnum, getCustomTableTheme } from '../../OrderPositions.utils'
import { OrderPositionsTableGlobalFiltersSection } from './content/OrderPositionsTableGlobalFiltersSection'
import { useOrderPositionsTableColumns } from './hooks/OrderPositionsTableColumns.hook'
import { useOrderPositionsTableExtraActions } from './hooks/OrderPositionsTableExtraActions.hook'
import { useOrderPositionsTableFilters } from './hooks/OrderPositionsTableFilters.hook'
import { useGlobalFilteredTableOrderPositions, useOrderPositionsTableSearchFn } from './hooks/OrderPositionsTableGlobalFilters.hook'
import { useOrderPositionsTableInitialData } from './hooks/OrderPositionsTableInitialData.hook'
import { TableOrderPosition, TableOrderPositionColumnId } from './OrderPositionsTable.type'
import { mapOrderPositionPropertyToCSV } from './OrderPositionsTable.utils'

export function OrderPositionsTable() {
  const theme: ThemeColorEnum = useSelector(selectTheme)
  const customTableTheme = useMemo(() => getCustomTableTheme(theme), [theme])
  const intl = useIntl()
  const columns: Array<DataTableColumnInfo<TableOrderPositionColumnId, TableOrderPosition>>
    = useOrderPositionsTableColumns()
  const {filtersData, filteredByColumnsTableOrderPositions}
    = useOrderPositionsTableFilters()
  const globalFilteredTableOrderPositions: Array<TableOrderPosition>
    = useGlobalFilteredTableOrderPositions(filteredByColumnsTableOrderPositions)
  const searchFn: DataTableSearchFn<TableOrderPosition> = useOrderPositionsTableSearchFn()
  const {setValueToStorage} = useLocalStorage('orderPositionsStage')
  const initData = useOrderPositionsTableInitialData(globalFilteredTableOrderPositions)

  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([])
  const extraActions = useOrderPositionsTableExtraActions(columns, selectedRowIds, globalFilteredTableOrderPositions)

  return <DataTable
    data={globalFilteredTableOrderPositions}
    columns={columns}
    pagination={{
      initSize: initData.paginationSize,
      initPageIndex: initData.paginationPage,
      sizesList: [5, 10, 15, 20],
      onPageIndexChange: index => setValueToStorage('paginationPage', index),
      onSizeChange: size => setValueToStorage('paginationSize', size),
    }}
    select={{
      type: 'multi',
      selectedRowIds,
      onChange: ids => setSelectedRowIds(ids),
    }}
    layout={{horizontalScroll: true}}
    filtersData={filtersData}
    searchData={{ searchFn }}
    exportCsv={{
      headerAccessor: columnId => intl.messages[`order_positions_table.columns.${columnId}`] as string,
      contentAccessor: mapOrderPositionPropertyToCSV,
    }}
    actionsSection={{
      subsection: <OrderPositionsTableGlobalFiltersSection />,
      className: 'justify-end items-end',
    }}
    theme={customTableTheme}
    extraActions={extraActions}
  />
}
