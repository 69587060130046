import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { getApi } from '../../../api/getApi'
import { BillData, GenerateBillAsyncResponse } from '../../../api/proposal/proposal'
import { setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { setBillPdfLinkAction } from '../../generate-table/reducer'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'

export const generateBillAction = createAsyncThunk<
  string,
  {
    orderNumber: string;
  }
>(
  'cart/generateBill',
  async ({ orderNumber }, { dispatch }) => {
    dispatch(setProposalGenerationWarnings([]))

    try {
      const { job_id: jobId }: GenerateBillAsyncResponse = await getApi()
        .proposal
        .generateBillAsync({
          dealId: +orderNumber,
        })

      const billData: BillData = await getApi()
        .proposal
        .getJobResult<BillData>({
          jobId,
        })

      const billPdfUrl = billData?.PdfUrl ?? null
      dispatch(setBillPdfLinkAction(billPdfUrl))
      notification.success({
        message: `Успешно`,
        description: 'Счет успешно сгенерирован',
      })

      return billPdfUrl
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при генерации счета',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }

    return ''
  },
)
