import { API_URL } from '../../constants/environment'
import axiosInstance from '../apiCreator'

export type ConvertCSVToXLSXResponse = Blob

type ExcelConverterServiceType = {
  convertCSVToXLS: (csvData: string, filename: string) => Promise<ConvertCSVToXLSXResponse>;
}

const ExcelConverterService: ExcelConverterServiceType = {
  convertCSVToXLS: (csvData: string, filename: string) => {
    const csvBlob = new Blob([csvData], { type: 'text/csv' })

    return axiosInstance
      .post<ConvertCSVToXLSXResponse>(
        `${API_URL.EXCEL_CONVERTER}/xlsx-convert?filename=${filename}`,
        csvBlob,
        {
          responseType: 'blob',
        },
      )
      .then(response => response.data)
  },
}

export default ExcelConverterService
