import {SnipOptions} from '../hooks/useSnip'

export const defaultOptions: SnipOptions = {
  method: 'css',
  lines: 3,
  midWord: true,
  ellipsis: '.\u200A.\u200A.',
}


export const supportsWebkitClamp = (): boolean =>
  typeof CSS !== 'undefined' &&
  CSS.supports('display', '-webkit-box') &&
  CSS.supports('-webkit-line-clamp', '3') &&
  CSS.supports('-webkit-box-orient', 'vertical')

export const getOptions = (userOptions: Partial<SnipOptions>): SnipOptions => ({
  method: supportsWebkitClamp() ? userOptions?.method ?? defaultOptions.method : 'js',
  lines: userOptions.lines ?? defaultOptions.lines,
  midWord: userOptions.midWord ?? defaultOptions.midWord,
  ellipsis: userOptions.ellipsis ?? defaultOptions.ellipsis,
})
