import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { setIsProposalGenerationLoading, setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'
import { setImportOrGenerateLoading, setImportTablePdfLinkAction } from '../../generate-table/reducer'
import { getApi } from '../../../api/getApi'
import { GenerateSpecificationAsyncResponse, ImportTableData } from '../../../api/proposal/proposal'

export const importTableAction = createAsyncThunk<
  string,
  {
    parentDealId: number;
    orderNumber: string;
  }
>(
  'cart/importTable',
  async ({
    parentDealId,
    orderNumber,
  }, { dispatch }) => {
    dispatch(setIsProposalGenerationLoading(true))
    dispatch(setProposalGenerationWarnings([]))
    dispatch(setImportTablePdfLinkAction(''))
    dispatch(setImportOrGenerateLoading(true))

    try {
      const { job_id: jobId }: GenerateSpecificationAsyncResponse =
        await getApi()
          .proposal
          .importTableAsync({
            parentDealId,
            dealId: +orderNumber,
          })

      const importData: ImportTableData =
        await getApi()
          .proposal
          .getJobResult<ImportTableData>({
            jobId,
          })

      const importTablePdfUrl: string = importData?.data ?? null

      dispatch(setImportTablePdfLinkAction(importTablePdfUrl))
      notification.success({
        message: 'Успешно',
        description: 'Таблица успешно импортирована',
      })

      return importTablePdfUrl
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при попытке импортировать таблицу',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }
    finally {
      dispatch(setIsProposalGenerationLoading(false))
    }

    return ''
  },
)
