import { FormattedMessage } from 'react-intl'

export const NotLinked = () => <>
  <div className='text-center mt-4'>
    <img
      className='img-fluid mb-5'
      src='/img/svg/into_the_night.svg'
      width='300'
      alt=''
    />
  </div>
  <h1 className='text-center'>
    <FormattedMessage id='orders.not_connected.header'/>
  </h1>
  <p className='text-center'>
    <FormattedMessage id='orders.not_connected.text'/>
  </p>
</>