import React from 'react'
import {ThemeSwitcherProvider} from 'react-css-theme-switcher'
import {IntlProvider} from 'react-intl'
import {THEME_CONFIG} from './config/AppConfig'
import {Views} from './views'
import './App.css'
import ruMessages from './lang/locales/ru.json'
import { useSelector } from 'react-redux'
import { selectTheme } from './store/theme/selectors'
import { ThemeColorEnum } from './views/OrdersView/OrderPositions.utils'
import { MantineProvider } from '@mantine/core'
import { ExternalLayer } from '@appscience/layers'
import { ModalProvider } from '@appscience/hooks'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function App() {
  const theme: ThemeColorEnum = useSelector(selectTheme)

  return (
    <div className='App'>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint='styles-insertion-point'
      >
        <IntlProvider
          defaultLocale='ru'
          locale='ru'
          messages={ruMessages}
        >
          <MantineProvider
            withNormalizeCSS
            theme={{
              colorScheme: theme,
            }}
          >
            <ModalProvider
              layer={ExternalLayer}
            >
              <Views />
            </ModalProvider>
          </MantineProvider>
        </IntlProvider>
      </ThemeSwitcherProvider>
    </div>
  )
}

export default App
