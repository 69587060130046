import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { setIsProposalGenerationLoading, setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'
import AttachService from '../../../api/attach/attach'

export const attachProposalAction = createAsyncThunk<
  void,
  {
    orderNumber: string;
    proposalDocUrl: string;
    proposalPdfUrl: string;
    spreadsheetUrl: string;
    proposalSpreadsheetUrl: string;
    proposalSpreadsheetRubUrl: string;
    proposalSpreadsheetUsdUrl: string;
  }
>(
  'cart/attachProposal',
  async ({
    orderNumber,
    proposalDocUrl,
    proposalPdfUrl,
    spreadsheetUrl,
    proposalSpreadsheetUrl,
    proposalSpreadsheetRubUrl,
    proposalSpreadsheetUsdUrl,
  }, { dispatch }) => {
    dispatch(setIsProposalGenerationLoading(true))
    dispatch(setProposalGenerationWarnings([]))

    try {
      await AttachService
        .attach({
          id: orderNumber,
          proposalDocUrl,
          proposalPdfUrl,
          spreadsheetUrl,
          proposalSpreadsheetUrl,
          proposalSpreadsheetRubUrl,
          proposalSpreadsheetUsdUrl,
        })
      notification.success({
        message: `Успешно`,
        description: 'Коммерческое предложение успешно прикреплено к сделке!',
      })
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при прикреплении коммерческого предложения к сделке',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }
    finally {
      dispatch(setIsProposalGenerationLoading(false))
    }
  },
)
