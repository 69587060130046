import { Text } from '@mantine/core'
import {DocumentDownloadIcon} from '@heroicons/react/outline'
import { useIntl } from 'react-intl'
import { DataTableColumnInfo } from '@appscience/data-table'
import { useMemo } from 'react'
import { ExtraActionData, useExcelExportPopup } from '../../../../../hooks/useExcelExportPopup/useExcelExportPopup'
import { TableOrderPosition, TableOrderPositionColumnId } from '../OrderPositionsTable.type'
import { mapOrderPositionPropertyToCSV } from '../OrderPositionsTable.utils'

export function useOrderPositionsTableExtraActions(
  columns: Array<DataTableColumnInfo<TableOrderPositionColumnId, TableOrderPosition>>,
  selectedRowIds: Array<string>,
  data: Array<TableOrderPosition>,
): Array<ExtraActionData> {
  const intl = useIntl()
  const messages = intl.messages as Record<string, string>

  const { openExportPopup } = useExcelExportPopup<TableOrderPositionColumnId, TableOrderPosition>({
    headerAccessor: columnId => messages[`order_positions_table.columns.${columnId}`],
    contentAccessor: mapOrderPositionPropertyToCSV,
  }, columns, selectedRowIds, data)

  return useMemo<Array<ExtraActionData>>(() => [{
    id: 'export-xls',
    text: intl.messages['order_positions_table.export.xlsx'] as string,
    icon: <DocumentDownloadIcon className='w-4 h-4' />,
    rightSection: <Text size='xs' color='dimmed'>Ctrl + Q</Text>,
    onClick: openExportPopup,
  }], [openExportPopup, intl.messages])
}
