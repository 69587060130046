import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectTheme } from '../../../../store/theme/selectors'
import { ThemeColorEnum } from '../../../../views/OrdersView/OrderPositions.utils'
import { NavItem } from '../../DefaultLayout'
import { DefaultNavItem } from '../DefaultNavItem/DefaultNavItem'
import cn from 'classnames'
import styles from './DefaultLeftNav.module.css'


type DefaultLeftNavProps = {
  navItems: Array<NavItem>;
}

export function DefaultLeftNav({ navItems }: DefaultLeftNavProps) {
  const theme: ThemeColorEnum = useSelector(selectTheme)
  const isDarkTheme = theme === ThemeColorEnum.Dark
  const { pathname } = useLocation()

  return (
    <div
      className={cn(
        styles['root'],
        { [styles['root--dark-theme']]: isDarkTheme },
      )}
    >
      {navItems.map(({ title, icon, link, isDisabled = false }, index) => (
        <DefaultNavItem
          key={index}
          title={title}
          icon={icon}
          link={link}
          isActive={link === pathname}
          isDisabled={isDisabled}
        />
      ))}
    </div>
  )
}
