import React, { useState } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { Drawer, Layout, Menu } from 'antd'
import { ThemeConfigurator } from '../../components/configurator/index'
import './old-app-header.css'
import { ENVIRONMENT, IS_PROD_ENVIRONMENT } from '../../constants/environment'

type OldAppHeaderProps = {
  title?: string;
}

export function OldAppHeader({ title = '' }: OldAppHeaderProps) {
  const [opened, setOpened] = useState<boolean>(false)

  return (
    <Layout.Header
      className='default-app-header'
    >
      {title && (
        <h2
          className='header-title'
        >
          {title}
        </h2>
      )}
      {!IS_PROD_ENVIRONMENT && (
        <span
          className='dev-text'
        >
          {ENVIRONMENT} env
        </span>
      )}
      <Menu
        mode='horizontal'
      >
        <Menu.Item
          key='panel'
          onClick={() => setOpened(true)}
        >
          <span>
            <SettingOutlined
              className='nav-icon mr-0'
            />
          </span>
        </Menu.Item>
      </Menu>
      <Drawer
        title='Configuration'
        placement='right'
        width={350}
        onClose={() => setOpened(false)}
        visible={opened}
      >
        <ThemeConfigurator />
      </Drawer>
    </Layout.Header>
  )
}
