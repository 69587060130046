import { DataTableColumnsExport, ExportCsvData, ExportCsvType } from '@appscience/data-table/content/actions-section/model'
import { MenuItemProps } from '@mantine/core'
import { useModal } from '@appscience/hooks'
import { useHotkeys } from '@mantine/hooks'
import { TableNode } from '@table-library/react-table-library'
import { PreExcelExportPopup } from './PreExcelExportPopup'
import { DataTableColumnInfo } from '@appscience/data-table'
import { verify } from '@appscience/utils'
import { downloadAsExcel, makeCsvData } from './useExcelExportPopup.utils'
import ExcelConverterService, { ConvertCSVToXLSXResponse } from '../../api/excel-converter/excel-converter'
import { notification } from 'antd'

export interface ExtraActionData extends Omit<MenuItemProps<'button'>, 'children'> {
  id: string,
  text: string,
}

export const DEFAULT_COLUMNS_EXPORT: ExportCsvType = 'onlyFull'

export function useExcelExportPopup<
  ColumnID extends string,
  ITEM extends TableNode
>(
  params: ExportCsvData<ColumnID, ITEM> | undefined,
  allColumns: Array<DataTableColumnInfo<ColumnID, ITEM>>,
  selectedRowIds: Array<string>,
  data: Array<TableNode>,
) {
  const [openPopup, hidePopup] = useModal(PreExcelExportPopup)

  function openExportPopup() {
    const {
      headerAccessor,
      contentAccessor,
    } = verify(params)
    const columnsExportData: DataTableColumnsExport = params?.columnsExportData || { type: DEFAULT_COLUMNS_EXPORT }

    const columns: Array<TitleWithId<ColumnID>> = allColumns.map(x => ({
      id: x.id as ColumnID,
      title: headerAccessor(x.id as ColumnID),
    }))

    async function onExport(filename: string, columnIds: Array<string>) {
      const selectedRowIdsSet = new Set(selectedRowIds || [])
      const exportedData = selectedRowIdsSet.size === 0
        ? data
        : data.filter(row => selectedRowIdsSet.has(row.id))
      const idsSet = new Set(columnIds)

      const csvData: string = makeCsvData({
        contentAccessor: (item, columnId) => contentAccessor(item as ITEM, columnId as ColumnID),
        columns: columns.filter(x => idsSet.has(x.id)),
        data: exportedData,
      })

      try {
        const response: ConvertCSVToXLSXResponse = await ExcelConverterService.convertCSVToXLS(csvData, filename)
        if (response) {
          downloadAsExcel(response, filename)
        }
      }
      catch (error: AnyType) {
        if (error?.message instanceof Blob) {
          const errorMessage: string = await (error?.message as Blob)?.text()
          notification.error({
            message: 'Error',
            description: errorMessage,
            duration: 5,
          })
          return
        }

        throw error
      }
    }

    openPopup({
      columns,
      canSelectColumns: columnsExportData.type === 'selective',
      defaultSelectedIds: columnsExportData.type === 'selective'
        ? columnsExportData.getDefaultSelectedIds && columnsExportData.getDefaultSelectedIds()
        : undefined,
      onChange: columnsExportData?.type === 'selective' ? columnsExportData.onChange : undefined,
      onExport: onExport,
      closeFn: () => hidePopup(),
    })
  }
  useHotkeys(params ? [['ctrl+q', openExportPopup]] : [])

  return {
    openExportPopup,
  }
}
