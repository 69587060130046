import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { setIsProposalGenerationLoading, setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'
import { getApi } from '../../../api/getApi'
import { getIdFromGoogleSpreadsheetUrl } from '../../../views/proposal-view/content/ProposalGeneration/ProposalGeneration.utils'
import { GenerateSpecificationAsyncResponse, SpecificationData } from '../../../api/proposal/proposal'
import { setSpecificationDocLinkAction, setSpecificationPdfLinkAction } from '../../generate-table/reducer'

export const generateSpecificationAction = createAsyncThunk<
  {
    specificationPdfUrl: string;
    specificationDocUrl: string;
  },
  {
    orderNumber: string;
    linkToTable: string;
  }
>(
  'cart/generateSpecification',
  async ({
    orderNumber,
    linkToTable,
  }, { dispatch }) => {
    dispatch(setIsProposalGenerationLoading(true))
    dispatch(setProposalGenerationWarnings([]))

    try {
      const { job_id: jobId }: GenerateSpecificationAsyncResponse =
        await getApi()
          .proposal
          .generateSpecificationAsync({
            spreadsheetId: getIdFromGoogleSpreadsheetUrl(linkToTable),
            dealId: +orderNumber,
          })

      const specificationData: SpecificationData =
        await getApi()
          .proposal
          .getJobResult<SpecificationData>({
            jobId,
          })

      const specificationPdfUrl: string = specificationData?.Pdf ?? null
      const specificationDocUrl: string = specificationData?.Doc ?? null

      dispatch(setSpecificationPdfLinkAction(specificationPdfUrl))
      dispatch(setSpecificationDocLinkAction(specificationDocUrl))

      notification.success({
        message: 'Успешно',
        description: 'Спецификация успешно сгенерирована',
      })

      return {
        specificationPdfUrl,
        specificationDocUrl,
      }
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при генерации спецификации',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)
    }
    finally {
      dispatch(setIsProposalGenerationLoading(false))
    }

    return {
      specificationPdfUrl: '',
      specificationDocUrl: '',
    }
  },
)
