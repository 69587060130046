import { Tooltip } from '@mantine/core'
import { Checkbox, CheckboxProps } from 'antd'
import { useEffect, useRef } from 'react'

type EnhancedCheckboxProps = CheckboxProps & {
  hintText?: string;
  onDoubleClick?: () => void;
}

export function EnhancedCheckbox({
  hintText = '',
  onDoubleClick,
  ...props
}: EnhancedCheckboxProps) {
  const checkboxRef = useRef<HTMLInputElement & { input: HTMLInputElement } | null>(null)

  useEffect(() => {
    if (!onDoubleClick) {
      return
    }

    const checkbox: HTMLInputElement | null = checkboxRef.current?.input ?? null
    if (!checkbox) {
      return
    }

    checkbox.addEventListener('dblclick', onDoubleClick, false)
    return function cleanup() {
      if (checkbox) {
        checkbox.removeEventListener('dblclick', onDoubleClick, false)
      }
    }
  }, [onDoubleClick])

  return (
    <Tooltip
      disabled={!hintText}
      label={hintText}
      styles={{
        root: {
          width: '100%',
        },
        body: {
          backgroundColor: '#777',
          opacity: 0.8,
        },
      }}
    >
      <Checkbox
        ref={checkboxRef}
        {...props}
      />
    </Tooltip>
  )
}
