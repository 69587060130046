import React, { useEffect, useRef } from 'react'
import { Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useCart } from './cart.hook'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { selectAddToCartBtnDisable } from '../../../../store/cart/cart.selectors'
import { useSelector } from 'react-redux'
import {selectImportTablePdfLink} from '../../../../store/generate-table/selectors'
import { CartItemCard } from './cart-item-card'

export function Cart() {
  const importTableLink = useSelector(selectImportTablePdfLink)
  const {
    cartItems,
    isCartLoading,
    isVisibleLoader,

    onCountHandler,
    initState,
    onStartEditHandler,
    onToggleNeedAnaloguesHandler,
    onSelectAllNeedAnaloguesHandler,
  } = useCart()

  useEffect(initState, [])

  const ref = useRef(null)
  const ref_1 = useRef(null)
  const ref_2 = useRef(null)

  const scrollTrigger = () => {
    //@ts-ignore
    ref.current && ref.current.scrollTop(ref.current!.getScrollHeight())
    //@ts-ignore
    ref_1.current && ref_1.current.scrollTop(ref_1.current!.getScrollHeight())
    //@ts-ignore
    ref_2.current && ref_2.current.scrollTop(ref_2.current!.getScrollHeight())
  }

  const cardRefTop = useRef<HTMLDivElement>(null)
  const cardRefBottom = useRef<HTMLDivElement>(null)
  const isDisabledCount = useSelector(selectAddToCartBtnDisable)

  if (isVisibleLoader && isCartLoading) {
    return (
      <div>
        <Scrollbars
          autoHeight
          ref={ref}
          autoHeightMin={0}
          autoHeightMax={400}
        >
          <Card
            bordered
          >
            <div className='d-flex justify-content-center'>
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </div>
          </Card>
        </Scrollbars>
      </div>
    )
  }

  if (isVisibleLoader && !isCartLoading && cartItems.length) {
    return (
      <div>
        <h3>Корзина</h3>
        <Scrollbars
          ref={ref_1}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={400}
        >
          <div
            ref={cardRefTop}
          >
            <Card
              bordered
            >
              <div className='test'>
                {cartItems.map((item, key) => (
                  <CartItemCard
                    {...item}
                    key={key}
                    isDisabledCount={isDisabledCount}
                    isLastItem={key === cartItems.length - 1}
                    itemIndex={key}
                    scrollTrigger={scrollTrigger}
                    onCount={onCountHandler}
                    onToggleNeedAnalogues={onToggleNeedAnaloguesHandler}
                    onSelectAllNeedAnalogues={onSelectAllNeedAnaloguesHandler}
                    onStartEditHandler={onStartEditHandler}
                  />
                ))}
              </div>
            </Card>
          </div>
        </Scrollbars>
      </div>
    )
  }

  if ((isVisibleLoader && !isCartLoading && !cartItems.length) || !cartItems.length) {
    return null
  }

  return (
    <>
      <h3>Корзина</h3>
      <div
        ref={cardRefBottom}
      >
        <Scrollbars
          autoHeight
          ref={ref_2}
          autoHeightMin={0}
          autoHeightMax={400}
        >
          <Card
            bordered
            style={{
              overflowY: 'auto',
              maxHeight: 400,
            }}
          >
            <div>
              {cartItems.map((item, key) =>
                <CartItemCard
                  {...item}
                  key={key}
                  isDisabledCount={isDisabledCount}
                  itemIndex={key}
                  isLastItem={key === cartItems.length - 1}
                  scrollTrigger={scrollTrigger}
                  onCount={onCountHandler}
                  onToggleNeedAnalogues={onToggleNeedAnaloguesHandler}
                  onSelectAllNeedAnalogues={onSelectAllNeedAnaloguesHandler}
                />,
              )}
            </div>
          </Card>
        </Scrollbars>
      </div>
    </>
  )
}
