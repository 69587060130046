import {Tooltip} from '@mantine/core'
import {mergeDeepRight} from 'ramda'
import React from 'react'

interface DefaultTooltipProps extends React.ComponentProps<typeof Tooltip> {
  maxWidth?: 'auto'|number,
}

function getDefaultStyles({ maxWidth }: DefaultTooltipProps) {
  return {
    body: {
      maxWidth: maxWidth === undefined
        ? '350px'
        : maxWidth === 'auto' ? 'auto' : `${maxWidth}px`,
    },
  }
}

export function DefaultTooltip(props: DefaultTooltipProps) {
  const styles = props.styles
    ? mergeDeepRight(getDefaultStyles(props), props.styles)
    : getDefaultStyles(props)

  return <Tooltip
    styles={styles}
    openDelay={300}
    closeDelay={100}
    wrapLines
    allowPointerEvents
    {...props}
    children={props.children}
  />
}

export function HintTooltip(props: DefaultTooltipProps) {
  return <DefaultTooltip
    position='bottom'
    openDelay={500}
    allowPointerEvents={false}
    styles={{
      body: {
        padding: '1px 8px',
        fontSize: '12px',
      },
    }}
    {...props}
    children={props.children}
  />
}

export function withHintTooltip(label: string, children: React.ReactNode) {
  return <HintTooltip label={label} children={children} />
}
