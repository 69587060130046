import { DataTableFiltersData } from '@appscience/data-table'
import { cleanArray, dateInRange, isNullable } from '@appscience/utils'
import { startOfDay } from 'date-fns'
import { values } from 'ramda'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTableOrderPositions } from '../../../../../store/order-positions-table/selectors'
import { DateRangePopover } from '../../DataRangePopover/data-range-popover'
import { MultiSelectListPopover } from '../../MultiSelectListPopover/multi-select-list-popover'
import { ORDER_ITEM_STATUSES, ORDER_ITEM_STATUS_TO_BADGE_CONFIG, OrderItemStatusEnum, OrderPosition, getSetsIntersection, unixTimestampToTimestamp } from '../../../OrderPositions.utils'
import { TableOrderPosition, TableOrderPositionColumnId } from '../OrderPositionsTable.type'
import { mapOrderPositionToTableOrderPosition, mapToSelectItem } from '../OrderPositionsTable.utils'
import { getStorageObject, useLocalStorage } from '../../../../../hooks/useLocalStorage/useLocalStorage'
import { SerializedOrderPositionsTableFilters } from '../../../../../hooks/useLocalStorage/types'

type StatusData = TitleWithId<string> & {
  status: OrderItemStatusEnum;
}

export interface OrderPositionsTableFilters {
  status: Array<string>,
  deadline: [Date|null, Date|null],
  expectedDeliveryDate: [Date|null, Date|null],
}

function serializeDate(date: Date | null): string | null {
  if (!date) {
    return null
  }

  return date.toDateString()
}

function deserializeDate(dateString: string | null): Date | null {
  if (!dateString) {
    return null
  }

  return new Date(dateString)
}

function serializeFilters(filters: OrderPositionsTableFilters): SerializedOrderPositionsTableFilters {
  if (!filters) {
    null
  }

  return {
    ...filters,
    deadline: [
      serializeDate(filters?.deadline?.[0]),
      serializeDate(filters?.deadline?.[1]),
    ],
    expectedDeliveryDate: [
      serializeDate(filters?.expectedDeliveryDate?.[0]),
      serializeDate(filters?.expectedDeliveryDate?.[1]),
    ],
  }
}

function deserializeFilters(filters: SerializedOrderPositionsTableFilters): OrderPositionsTableFilters {
  if (!filters) {
    null
  }

  return {
    ...filters,
    deadline: [
      deserializeDate(filters?.deadline?.[0]),
      deserializeDate(filters?.deadline?.[1]),
    ],
    expectedDeliveryDate: [
      deserializeDate(filters?.expectedDeliveryDate?.[0]),
      deserializeDate(filters?.expectedDeliveryDate?.[1]),
    ],
  }
}


function getInitialFiltersValues(): OrderPositionsTableFilters {
  const serializedFilters: SerializedOrderPositionsTableFilters
    = getStorageObject('orderPositionsStage')?.columnFilters ?? {
      status: [],
      deadline: [null, null],
      expectedDeliveryDate: [null, null],
    }
  const deserializedFilters: OrderPositionsTableFilters
    = deserializeFilters(serializedFilters)
  const {status, deadline, expectedDeliveryDate} = deserializedFilters

  return {
    status: Array.isArray(status)
      ? status
      : [],
    deadline: Array.isArray(deadline)
      ? deadline
      : [null, null],
    expectedDeliveryDate: Array.isArray(expectedDeliveryDate)
      ? expectedDeliveryDate
      : [null, null],
  }
}

function filterByStatus(
  statuses: Array<StatusData>,
  selectedIds: Array<string>,
  tableOrderPositions: Array<TableOrderPosition>,
): Set<string>|null {
  if (selectedIds.length === 0) {
    return null
  }
  const selectedIdsSet = new Set(selectedIds)
  const selectedStatusesSet = new Set<OrderItemStatusEnum>(
    statuses.reduce((selectedStatuses: Array<OrderItemStatusEnum>, statusData: StatusData) => {
      if (selectedIdsSet.has(statusData.id)) {
        selectedStatuses.push(statusData.status)
      }
      return selectedStatuses
    }, []),
  )

  // see https://appscience.atlassian.net/browse/DT-64?focusedCommentId=14653
  if (
    selectedStatusesSet.has(OrderItemStatusEnum.PreOrder)
    || selectedStatusesSet.has(OrderItemStatusEnum.Procurement)
  ) {
    selectedStatusesSet
      .add(OrderItemStatusEnum.PreOrder)
      .add(OrderItemStatusEnum.Procurement)
  }

  const filteredTableOrderIdsSet: Set<string> = new Set()
  tableOrderPositions.forEach(orderPosition => {
    if (selectedStatusesSet.has(orderPosition.status)) {
      filteredTableOrderIdsSet.add(orderPosition.id)
    }
  })
  return filteredTableOrderIdsSet
}

type OrderPositionsTableDateFilterColumnId = Extract<TableOrderPositionColumnId, 'deadline' | 'expectedDeliveryDate'>

function filterByDateColumn(
  column: OrderPositionsTableDateFilterColumnId,
  range: [Date|null, Date|null]|null,
  tableOrderPositions: Array<TableOrderPosition>,
): Set<string> | null {
  if (range === null || range[0] === null || range[1] === null) {
    return null
  }

  const filteredOrderIds: Array<string> = tableOrderPositions
    .filter((orderPosition: TableOrderPosition) => {
      const timestamp = orderPosition[column]
      return isNullable(timestamp)
        ? null
        : dateInRange(startOfDay(unixTimestampToTimestamp(timestamp || 0)), range as [Date, Date])
    })
    .map(order => order.id)
  return new Set(filteredOrderIds)
}

type OrderPositionsTableFiltersResult = {
  filtersData: DataTableFiltersData<TableOrderPositionColumnId>;
  filteredByColumnsTableOrderPositions: Array<TableOrderPosition>;
}

export function useOrderPositionsTableFilters(): OrderPositionsTableFiltersResult {
  const {setValueToStorage} = useLocalStorage('orderPositionsStage')
  const orderPositions: Array<OrderPosition> = useSelector(selectTableOrderPositions)
  const tableOrderPositions: TableOrderPosition[] = orderPositions
    .map(mapOrderPositionToTableOrderPosition)

  const statusToTitleMap = useMemo(() => new Map(
    orderPositions
      .map(({ status, statusDescription }) => [
        status,
        statusDescription || ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status]?.message || status,
      ]),
  ), [orderPositions])

  const statuses: Array<StatusData> = useMemo(() =>
    ORDER_ITEM_STATUSES
      // see https://appscience.atlassian.net/browse/DT-64?focusedCommentId=14653
      .filter(status => statusToTitleMap.has(status) && status !== OrderItemStatusEnum.PreOrder)
      .map((status, index) => ({
        id: `${index}`,
        title: statusToTitleMap.get(status) || ORDER_ITEM_STATUS_TO_BADGE_CONFIG[status]?.message || status,
        status,
      }))
  , [statusToTitleMap])

  const statusesList = useMemo(() => statuses.map(mapToSelectItem), [statuses])

  const [filtersValues, setFiltersValues] = useState<OrderPositionsTableFilters>(getInitialFiltersValues())
  const updateFilterValue = <K extends keyof OrderPositionsTableFilters>(column: K, value: OrderPositionsTableFilters[K]) => {
    setFiltersValues(prev => {
      const newState = {...prev, [column]: value}
      const serializedFilters: SerializedOrderPositionsTableFilters = serializeFilters(newState)

      setValueToStorage('columnFilters', serializedFilters)

      return newState
    })
  }
  const [filteredColumnIds, setFilteredColumnIds] = useState<TypedObject<TableOrderPositionColumnId, Set<string>|null>>({
    status: filterByStatus(statuses, filtersValues.status, tableOrderPositions),
    deadline: filterByDateColumn('deadline', filtersValues.deadline, tableOrderPositions),
    expectedDeliveryDate: filterByDateColumn('expectedDeliveryDate', filtersValues.expectedDeliveryDate, tableOrderPositions),
  })
  const updateFilteredColumnIds = (column: TableOrderPositionColumnId, value: null|Set<string>) => {
    setFilteredColumnIds(prev => ({...prev, [column]: value}))
  }
  function confirmDateFilter(column: OrderPositionsTableDateFilterColumnId, range: [Date, Date]|null) {
    updateFilteredColumnIds(column, filterByDateColumn(column, range, tableOrderPositions))
    updateFilterValue(column, range === null ? [null, null] : range)
  }

  const filtersData: DataTableFiltersData<TableOrderPositionColumnId> = {
    'status': {
      filterActive: filtersValues['status'].length > 0,
      dropdown: ({setActive, closeFn}) => <MultiSelectListPopover
        selectedIds={filtersValues['status']}
        data={statusesList}
        saveButton={{
          onClick: ids => {
            updateFilteredColumnIds('status', filterByStatus(statuses, ids, tableOrderPositions))
            updateFilterValue('status', ids.length > 0 ? ids : [])
            setActive(ids.length > 0)
            closeFn()
          },
        }}
        listClassName='max-h-[300px]'
        className='w-[280px]'
      />,
      // searchable
    },
    'deadline': {
      filterActive: cleanArray(filtersValues['deadline']).length > 0,
      dropdown: props => <DateRangePopover
        value={filtersValues['deadline']}
        confirmValue={range => {
          confirmDateFilter('deadline', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
    },
    'expectedDeliveryDate': {
      filterActive: cleanArray(filtersValues['expectedDeliveryDate']).length > 0,
      dropdown: props => <DateRangePopover
        value={filtersValues['expectedDeliveryDate']}
        confirmValue={range => {
          confirmDateFilter('expectedDeliveryDate', range)
          props.setActive(range !== null)
          props.closeFn()
        }}
      />,
    },
  }

  const idsSets: Array<Set<string>> = cleanArray(values(filteredColumnIds)) || []
  if (!idsSets.length) {
    return {
      filtersData,
      filteredByColumnsTableOrderPositions: tableOrderPositions,
    }
  }

  const visibleRowIds: Set<string> = getSetsIntersection<string>(idsSets)
  // intersectionSets

  return {
    filtersData,
    filteredByColumnsTableOrderPositions:
      tableOrderPositions.filter(orderPosition => visibleRowIds.has(orderPosition.id)),
  }
}