import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from './ThemeConstant'
import {ScreenSize} from '../hooks/useScreenSize/useScreenSize'
import { ThemeColorEnum } from '../views/OrdersView/OrderPositions.utils'

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: (localStorage.getItem('theme') as ThemeColorEnum) ?? ThemeColorEnum.Light,
  direction: DIR_LTR,
  deviceType: ScreenSize.Desktop,
  isMobile: false,
}
export const AUTH_TOKEN = 'x-auth-token'
