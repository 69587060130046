import { searchReducer } from './search/reducer'
import { authReducer } from './auth/reducer'
import { themeReaducer } from './theme/reducer'
import { combineReducers } from 'redux'
import { RootReducer } from './types'
import { cartReducer } from './cart/cart.reducer'
import { generateReducer } from './generate-table/reducer'
import { orderPositionsTableReducer } from './order-positions-table/reducer'
import { proposalGenerationReducer } from './proposal-generation'

export const rootReducer = combineReducers<RootReducer>({
  search: searchReducer,
  auth: authReducer,
  theme: themeReaducer,
  cart: cartReducer,
  generate: generateReducer,
  orderPositionsTable: orderPositionsTableReducer,
  proposalGeneration: proposalGenerationReducer,
})
