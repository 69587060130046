import { createSlice } from '@reduxjs/toolkit'
import { ActionType } from '../types'
import { generateBillAction } from '../cart/cart-async-actions/generate-bill'

type ProposalGenerationState = {
  isLoading: boolean;
  warnings: Array<string>;
}

const initialState: ProposalGenerationState = {
  isLoading: false,
  warnings: [],
}

const proposalGenerationSlice = createSlice({
  name: 'proposalGeneration',
  initialState,
  reducers: {
    setIsProposalGenerationLoading(state, action: ActionType<boolean>) {
      state.isLoading = action.payload
    },
    setProposalGenerationWarnings(state, action: ActionType<Array<string>>) {
      state.warnings = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(generateBillAction.pending, state => {
        state.isLoading = true
      })
      .addCase(generateBillAction.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(generateBillAction.rejected, state => {
        state.isLoading = false
      })
  },
})

export const proposalGenerationReducer = proposalGenerationSlice.reducer
export const {
  setIsProposalGenerationLoading,
  setProposalGenerationWarnings,
} = proposalGenerationSlice.actions
