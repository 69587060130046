import {createSlice} from '@reduxjs/toolkit'
import {THEME_CONFIG} from '../../config/AppConfig'
import {ScreenSize} from '../../hooks/useScreenSize/useScreenSize'
import { ThemeColorEnum } from '../../views/OrdersView/OrderPositions.utils'
import {ActionType} from '../types'

const initialState: typeof THEME_CONFIG = {
  ...THEME_CONFIG,
}

type DeviceType= ScreenSize

type DeviceModeType = ActionType<DeviceType>
type HeaderNavColor = ActionType<string>
type Theme = ActionType<ThemeColorEnum>

const themeSlice = createSlice({
  name: 'theme',
  initialState: {...initialState},
  reducers: {
    setDeviceMode(state, actions: DeviceModeType) {
      state.deviceType = actions.payload
    },
    setHeaderNavColor(state, actions: HeaderNavColor) {
      state.headerNavColor = actions.payload
    },
    setTheme(state, actions: Theme) {
      state.currentTheme = actions.payload
    },
  },
})

export const themeReaducer = themeSlice.reducer
export const {
  setDeviceMode,
  setHeaderNavColor,
  setTheme,
} = themeSlice.actions
