import { DataTableColumnInfo } from '@appscience/data-table'
import { caseInsensitiveCompare, defaultCompare } from '@appscience/utils'
import { useIntl } from 'react-intl'
import { ORDER_ITEM_STATUS_TO_PRIORITY, mapOrderItemStatusToBadge, unixTimestampToFormattedDate } from '../../../OrderPositions.utils'
import { LinesEllipsisWithTooltip } from '../../LinesEllipsisWithTooltip/LinesEllipsisWithTooltip'
import { Comment, OrderPositionsTableCommentsList } from '../content/OrderPositionsTableCommentsList'
import { OrderPositionsTableOrderId } from '../content/OrderPositionsTableOrderId'
import { OrdersPositionsTableComment } from '../content/OrdersPositionsTableComment'
import { TableOrderPosition, TableOrderPositionColumnId } from '../OrderPositionsTable.type'

export function useOrderPositionsTableColumns(): Array<DataTableColumnInfo<TableOrderPositionColumnId, TableOrderPosition>> {
  const intl = useIntl()

  return [{
    id: 'clientName',
    content: (orderPosition: TableOrderPosition) =>
      <LinesEllipsisWithTooltip
        text={orderPosition.clientName || '-'}
        maxLines={3}
      />,
    fr: 8,
    minWidth: '160px',
    title: intl.messages['order_positions_table.columns.clientName'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => caseInsensitiveCompare(a.clientName, b.clientName),
  }, {
    id: 'orderId',
    content: (orderPosition: TableOrderPosition) =>
      <OrderPositionsTableOrderId orderId={orderPosition.orderId} /> || '-',
    fr: 7,
    minWidth: '140px',
    title: intl.messages['order_positions_table.columns.orderId'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => defaultCompare(+(a.orderId || 0), +(b.orderId || 0)),
  }, {
    id: 'id',
    content: (orderPosition: TableOrderPosition) => `${orderPosition.id}` || '-',
    fr: 4,
    minWidth: '80px',
    title: intl.messages['order_positions_table.columns.id'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => defaultCompare(+(a.id || 0), +(b.id || 0)),
  }, {
    id: 'name',
    content: (orderPosition: TableOrderPosition) =>
      <LinesEllipsisWithTooltip
        text={orderPosition.name || '-'}
        maxLines={3}
      />,
    fr: 15,
    minWidth: '300px',
    title: intl.messages['order_positions_table.columns.name'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => caseInsensitiveCompare(a.name, b.name),
  }, {
    id: 'deadline',
    content: (orderPosition: TableOrderPosition) =>
      unixTimestampToFormattedDate(orderPosition.deadline) || '-',
    fr: 6,
    minWidth: '120px',
    title: intl.messages['order_positions_table.columns.deadline'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => (a.deadline || 0) - (b.deadline || 0),
  }, {
    id: 'expectedDeliveryDate',
    content: (orderPosition: TableOrderPosition) =>
      unixTimestampToFormattedDate(orderPosition.expectedDeliveryDate) || '-',
    fr: 7,
    minWidth: '140px',
    title: intl.messages['order_positions_table.columns.expectedDeliveryDate'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => (a.expectedDeliveryDate || 0) - (b.expectedDeliveryDate || 0),
  }, {
    id: 'status',
    content: (orderPosition: TableOrderPosition) =>
      mapOrderItemStatusToBadge(
        orderPosition?.status,
        orderPosition?.statusDescription,
        orderPosition?.lastStatusDate,
      ) || '-',
    fr: 16,
    minWidth: '320px',
    title: intl.messages['order_positions_table.columns.status'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) =>
      (ORDER_ITEM_STATUS_TO_PRIORITY[a?.status] || 0) - (ORDER_ITEM_STATUS_TO_PRIORITY[b?.status] || 0),
  // }, {
  //   id: 'causeOfWaiting',
  //   content: (orderPosition: TableOrderPosition) => orderPosition.causeOfWaiting || '-',
  //   fr: 10,
  //   minWidth: '200px',
  //   title: intl.messages['order_positions_table.columns.causeOfWaiting'] as string,
  //   sort: (a: TableOrderPosition, b: TableOrderPosition) => caseInsensitiveCompare(a.causeOfWaiting, b.causeOfWaiting),
  }, {
    id: 'procurementComment',
    content: (orderPosition: TableOrderPosition) => {
      const comments: Array<Comment> = [
        {
          title: 'Proc',
          comment: orderPosition.procurementComment,
          color: 'teal',
        }, {
          title: 'Sd',
          comment: orderPosition.sdComment,
          color: 'cyan',
        },
      ].filter(({comment}) => Boolean(comment))

      if (!comments.length) {
        return '-'
      }

      return <OrderPositionsTableCommentsList comments={comments}/>
    },
    fr: 15,
    minWidth: '300px',
    title: intl.messages['order_positions_table.columns.procurementComment'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => caseInsensitiveCompare(a.procurementComment, b.procurementComment),
  }, {
    id: 'comment',
    content: (orderPosition: TableOrderPosition) =>
      <OrdersPositionsTableComment
        twinUserItemId={+orderPosition.id}
        comment={orderPosition.comment}
      />,
    fr: 15,
    minWidth: '300px',
    title: intl.messages['order_positions_table.columns.comment'] as string,
    sort: (a: TableOrderPosition, b: TableOrderPosition) => caseInsensitiveCompare(a.comment, b.comment),
  }]
}
