import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SentryFallback } from './components/SentryFallback/SentryFallback'

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://f5de6d009d6f4f979eed15c4bf2ea246@o1092268.ingest.sentry.io/6650124',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  })
}

function Root() {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={props => <SentryFallback {...props} />}
      >
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
