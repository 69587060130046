import styles from './themeLoading.module.css'
import React from 'react'
import {joinStrings} from '../../utils/string'

type LoadingType = {
  className?: string,
}

const ThemeLoading: React.FC<LoadingType> = ({
  className,
}) => (
  <div className={joinStrings(
    className,
    styles.container,
  )}>
    <div className={styles['sbl-circ']}></div>
  </div>
)

export default ThemeLoading
