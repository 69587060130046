import { ButtonContent } from './ButtonContent'

type ProposalActionsButtonProps = {
  link: string;
  iconSrc: string;
  message?: string;
}

export function ProposalActionsButton({
  link,
  iconSrc,
  message = '',
}: ProposalActionsButtonProps) {
  const isButtonDisabled = !link

  return (
    <ButtonContent
      isButtonDisabled={isButtonDisabled}
      iconSrc={iconSrc}
      message={message}
      link={link}
    />
  )
}
