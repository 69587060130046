import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectProposalGeneration = (state: RootState) => state.proposalGeneration

export const selectIsProposalGenerationLoading = createSelector(
  selectProposalGeneration,
  state => state.isLoading,
)
export const selectProposalGenerationWarnings = createSelector(
  selectProposalGeneration,
  state => state.warnings,
)
