const UA_LINE_HEIGHT = 1.2

export const elementLines = (el: HTMLElement): number => {
  const computedStyle = window.getComputedStyle(el)
  const computedHeight = parseInt(computedStyle.height, 10)
  const computedLineHeight = computedStyle.lineHeight === 'normal'
    ? parseInt(computedStyle.fontSize, 10) * UA_LINE_HEIGHT
    : parseInt(computedStyle.lineHeight, 10)

  if (computedHeight === 0 && computedLineHeight === 0) {
    return 0
  }

  return Math.ceil(computedHeight / computedLineHeight)
}
