import { Button, Col } from 'antd'
import { OpenLinkIcon, ProposalActionIcon } from '../ProposalActionsIcon/ProposalActionsIcon'
import pdfIconSrc from '../../../../../../assets/svg/pdf-icon.svg'
import googleDocsIconSrc from '../../../../../../assets/svg/google-docs-icon.svg'
import { useSelector } from 'react-redux'
import { selectSpecificationDocLink, selectSpecificationPdfLink } from '../../../../../../store/generate-table/selectors'

type SpecificationGenerationActionProps = {
  isGenerationButtonDisabled: boolean;
  generateSpecification: () => void;
}

export function SpecificationGenerationAction({
  isGenerationButtonDisabled,
  generateSpecification,
}: SpecificationGenerationActionProps) {
  const specificationPdfLink = useSelector(selectSpecificationPdfLink)
  const specificationDocLink = useSelector(selectSpecificationDocLink)

  return (
    <>
      <Col
        span='9'
      >
        <Button
          type='primary'
          block
          className='mb-4'
          disabled={isGenerationButtonDisabled}
          onClick={generateSpecification}
        >
          Сгенерировать Спецификацию
        </Button>
      </Col>
      <Col
        span='4'
        className='pl-2'
      >
        <Button
          block
          className='mb-4'
          disabled={!specificationPdfLink}
        >
          <a
            href={specificationPdfLink}
            target='_blank'
            className='d-flex align-items-center justify-content-center'
          >
            <div className='mr-1'>
              <OpenLinkIcon />
            </div>
            <ProposalActionIcon
              src={pdfIconSrc}
            />
          </a>
        </Button>
      </Col>
      <Col
        span='4'
        className='pl-2'
      >
        <Button
          block
          className='mb-4'
          disabled={!specificationDocLink}
        >
          <a
            href={specificationDocLink}
            target='_blank'
            className='d-flex align-items-center justify-content-center'
          >
            <div className='mr-1'>
              <OpenLinkIcon />
            </div>
            <ProposalActionIcon
              src={googleDocsIconSrc}
            />
          </a>
        </Button>
      </Col>
    </>
  )
}
