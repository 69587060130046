import {ApiErrorCodeEnum} from './apiErrorCode.enum'

interface CustomError {
  message: string
  ruText: string
  reasonCode: string
}

export const DEFAULT_CUSTOM_ERROR: CustomError = {
  reasonCode: 'not_defined',
  ruText: 'Неизвестная ошибка',
  message: '',
}

export class ApiError extends Error {
  private reasonCode: string
  private ruText: string

  constructor(data: CustomError) {
    const {reasonCode, ruText, message} = data
    super(message)

    Object.setPrototypeOf(this, ApiError.prototype)
    this.ruText = ruText
    this.reasonCode = reasonCode
  }

  getCode(): number {
    // @ts-ignore
    return ApiErrorCodeEnum[this.reasonCode]
  }

  getRuText(): string {
    return this.ruText
  }
}
