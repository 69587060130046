import { Badge, Divider, MantineColor } from '@mantine/core'
import { LinesEllipsisWithTooltip } from '../../LinesEllipsisWithTooltip/LinesEllipsisWithTooltip'

export type Comment = {
  title: string;
  comment: string;
  color: MantineColor;
}

type CommentItemProps = Comment

function CommentItem({ title, comment, color }: CommentItemProps) {
  return (
    <div style={{display: 'flex'}}>
      <div style={{width: 50, minWidth: 50}}>
        <Badge
          size='sm'
          radius='sm'
          variant='outline'
          color={color}
          fullWidth
        >
          {title}
        </Badge>
      </div>
      &nbsp;
      <LinesEllipsisWithTooltip
        text={comment}
        maxLines={3}
      />
    </div>
  )
}

type OrderPositionsTableCommentsListProps = {
  comments: Array<Comment>
}

export function OrderPositionsTableCommentsList({ comments }: OrderPositionsTableCommentsListProps) {
  const commentsSize: number = comments.length

  return (
    <div className='comments'>
      {comments.map(({ title, comment, color }, index) => (
        <div key={index}>
          <CommentItem
            title={title}
            comment={comment}
            color={color}
          />
          {index + 1 < commentsSize && (
            <Divider
              my={6}
              variant='dashed'
            />
          )}
        </div>
      ))}
    </div>
  )
}
