import {notification} from 'antd'

export const notificationError = (message: string, duration = 2) => {
  notification.error({
    message,
    duration,
  })
}

export const notificationSuccess = (message: string, duration = 1.5) => {
  notification.success({
    message,
    duration,
  })
}

export const notificationWarning = (message: string, duration = 1.5) => {
  notification.warning({
    message,
    duration,
  })
}
