import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getApi } from '../api/getApi'
import { AUTH_TOKEN } from '../config/AppConfig'
import { APP_SCIENCE_EMAIL_KEY } from '../constants/localStorage'
import { setEmailAction } from '../store/auth/reducer'

export function useCheckAuth() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const email = localStorage.getItem(APP_SCIENCE_EMAIL_KEY) ?? ''
    dispatch(setEmailAction(email))
  }, [dispatch])

  const checkAuthStatus = () => {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (token) {
      getApi().auth.check()
    }
    else {
      const redirect = `${location.pathname}${location.search}`
      history.push(redirect ? `/auth/login?redirect=${redirect}` : `/auth/login`)
    }
  }

  useEffect(() => {
    checkAuthStatus()
  }, [checkAuthStatus])
}