import { TableGeneration } from './components/TableGeneration/TableGeneration'
import { ProposalActions } from './components/ProposalActions/ProposalActions'
import { useProposalGeneration } from './ProposalGeneration.hook'

export function ProposalGeneration() {
  const {
    isLoading,
    importOrGenerateLoading,
    isGenerationButtonDisabled,
    isAttachButtonDisabled,
    warnings,
    linkToTable,
    importTable,
    generateSpreadsheet,
    generateProposal,
    generateBill,
    generateSpecification,
    attachProposal,
  } = useProposalGeneration()

  return (
    <>
      <TableGeneration
        isLoading={isLoading}
        isButtonDisabled={isGenerationButtonDisabled}
        generateSpreadsheet={generateSpreadsheet}
        importTable={importTable}
      />

      {(linkToTable || importOrGenerateLoading) && (
        <ProposalActions
          isLoading={isLoading}
          isGenerationButtonDisabled={isGenerationButtonDisabled}
          isAttachButtonDisabled={isAttachButtonDisabled}
          warnings={warnings}
          generateProposal={() => generateProposal(linkToTable)}
          generateBill={generateBill}
          generateSpecification={generateSpecification}
          attachProposal={attachProposal}
        />
      )}
    </>
  )
}
