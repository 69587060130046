import { createAsyncThunk } from '@reduxjs/toolkit'
import { setImportOrGenerateLoading } from '../../generate-table/reducer'
import { GenerateProposalSpreadsheetAsyncResponse } from '../../../api/spreadsheet/spreadsheet'
import { getApi } from '../../../api/getApi'
import { CartItem } from '../types'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { ApiError } from '../../../api/apiError/apiError'

export const generateSpreadsheetUrlAction = createAsyncThunk<
  string,
  {
    orderNumber: string;
    cartItems: Array<CartItem>;
  }
>(
  'cart/generateSpreadsheetUrl',
  async ({ orderNumber, cartItems }, { dispatch }) => {
    try {
      dispatch(setImportOrGenerateLoading(true))

      const { job_id: jobId }: GenerateProposalSpreadsheetAsyncResponse =
        await getApi()
          .spreadsheet
          .generateProposalSpreadsheetAsync({
            id: orderNumber,
            items: cartItems.map(item => ({
              ...item,
              price: item.price || 0,
              count: item.count || 0,
            })),
          })

      return getApi()
        .proposal
        .getJobResult<string>({
          jobId,
        })
    }
    catch (e) {
      const error = e as ApiError
      notification.error({
        message: 'Ошибка при генерации таблицы',
        description: error.getRuText?.() || error.message,
        duration: 10,
      })
      Sentry.captureException(error)

      throw error
    }
  },
)
