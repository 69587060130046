import apiCreator from '../apiCreator'
import { CancelToken } from 'axios'
import { ProductType } from '../../store/search/types'
import { API_URL } from '../../constants/environment'

function getVendorsParam(vendors: Array<string>): string {
  return vendors
    .map(vendor => `vendors=${vendor}`)
    .join('&')
}

function getClassParam(vendors: Array<string>): string {
  return vendors
    .map(vendor => `class=${vendor}`)
    .join('&')
}

export type GetPartnerProductsRequest = {
  vendors: Array<string>;
  any: string;
  count: number;
  page: number;
}

export type GetPartnerProductsResponse = {
  items_count: number;
  pages_count: number;
  partner_products: Array<ProductType>;
}

export type GetSuggestionsRequest = {
  vendors: Array<string>;
  part: string;
  count: number;
}

export type SuggestionText = {
  hl: boolean;
  text: string;
}

export type Suggestion = {
  data: ProductType;
  text: Array<SuggestionText>;
  weight: number;
}

export type GetSuggestionsResponse = {
  suggestions: Array<Suggestion>;
}

type SearchApi = {
  getPartnerProducts: (request: GetPartnerProductsRequest, cancelToken: CancelToken) =>
    Promise<GetPartnerProductsResponse>;
  getSuggestions: (request: GetSuggestionsRequest, cancelToken: CancelToken) =>
    Promise<GetSuggestionsResponse>;
}

export const searchApi: SearchApi = {
  getPartnerProducts: (
    { vendors, any, count, page }: GetPartnerProductsRequest,
    cancelToken: CancelToken,
  ): Promise<GetPartnerProductsResponse> =>
    apiCreator
      .get<GetPartnerProductsResponse>(
        `${API_URL.PARTNER_PRODUCTS}/request?${getVendorsParam(vendors)}`,
        {
          params: {
            any,
            count,
            page,
          },
          cancelToken,
        },
      )
      .then(({ data }) => data),
  getSuggestions: (
    { vendors, part, count }: GetSuggestionsRequest,
    cancelToken: CancelToken,
  ): Promise<GetSuggestionsResponse> =>
    apiCreator
      .get<GetSuggestionsResponse>(
        `${API_URL.PARTNER_PRODUCTS}/suggest-request?${getClassParam(vendors)}`,
        {
          params: {
            part,
            count,
          },
          cancelToken,
        },
      )
      .then(({ data }) => data),
}
