import {Input, Modal} from 'antd'
import { useCallback, useState } from 'react'

type TableGenerationModalProps = {
  isVisible: boolean;
  onClose: () => void;
  importTable: (parentDealId: number) => void;
}

export function TableImportModal({
  isVisible,
  onClose,
  importTable,
}: TableGenerationModalProps) {
  const [parentDealId, setParentDealId] = useState('')

  const cancelImportTable = useCallback(() => {
    onClose()
    setParentDealId('')
  }, [onClose, setParentDealId])

  const confirmImportTable = useCallback(() => {
    if (parentDealId) {
      importTable(+parentDealId)
      cancelImportTable()
    }
  }, [importTable, cancelImportTable, parentDealId])

  return (
    <Modal
      title='Импортировать таблицу из сделки'
      visible={isVisible}
      onOk={confirmImportTable}
      onCancel={cancelImportTable}
      centered
      okText='Импортировать'
      cancelText='Отмена'
      width={370}
    >
      <Input
        placeholder='Введите номер сделки'
        value={parentDealId}
        onChange={e => setParentDealId(e.target.value)}
      />
    </Modal>
  )
}
