import { useCheckAuth } from '../../hooks/useCheckAuth'
import { DefaultHeader } from './components/DefaultHeader/DefaultHeader'
import { DefaultLeftNav } from './components/DefaultLeftNav/DefaultLeftNav'
import { CreditCardIcon, DocumentTextIcon, GiftIcon, PencilAltIcon } from '@heroicons/react/outline'
import { useMemo, useState } from 'react'
import { AppRoute } from '../../routes/types'
import cn from 'classnames'
import styles from './DefaultLayout.module.css'

export function withDefaultLayout(
  children: React.ReactNode,
  headerTitle: string,
) {
  return (
    <DefaultLayout
      headerTitle={headerTitle}
    >
      {children}
    </DefaultLayout>
  )
}

export type NavItem = {
  title: string;
  icon: React.FC<React.ComponentProps<'svg'>>;
  link: AppRoute;
  isActive?: boolean;
  isDisabled?: boolean;
}

const MAV_ITEMS_CONFIG: Array<NavItem> = [{
  title: 'Генератор КП',
  icon: DocumentTextIcon,
  link: '/proposal',
}, {
  title: 'Модерация сделок',
  icon: PencilAltIcon,
  link: '/moderation',
}, {
  title: 'Оплаты',
  icon: CreditCardIcon,
  link: '/payments',
}, {
  title: 'Бонусы',
  icon: GiftIcon,
  link: '/bonuses',
}]

type DefaultLayoutProps = {
  children: Array<React.ReactNode> | React.ReactNode,
  headerTitle: string;
}

export function DefaultLayout({ headerTitle, children }: DefaultLayoutProps) {
  useCheckAuth()
  const navItems = useMemo<Array<NavItem>>(() => MAV_ITEMS_CONFIG, [])
  const [isNavOpened, setNavOpened] = useState(false)

  return (
    <div className='h-full'>
      <DefaultHeader
        headerTitle={headerTitle}
        isNavOpened={isNavOpened}
        onNavToggle={() => setNavOpened(isOpened => !isOpened)}
      />
      <div className={styles['body']}>
        {isNavOpened && (
          <DefaultLeftNav
            navItems={navItems}
          />
        )}
        <div
          className={cn(
            styles['content'],
            { [styles['content--nav-opened']]: isNavOpened },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
