import {Spin} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

type LoadingProps = {
  align?: string;
  cover?: string;
}

export function Loading({ align = '', cover = '' }: LoadingProps) {
  return (
    <div className={`loading text-${align} cover-${cover}`}>
      <Spin indicator={Icon} />
    </div>
  )
}
