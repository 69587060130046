import { createAsyncThunk } from '@reduxjs/toolkit'
import { setIsProposalGenerationLoading, setProposalGenerationWarnings } from '../../proposal-generation/proposal-generation.reducer'
import { notification } from 'antd'
import { setImportOrGenerateLoading, setLinkToTableAction } from '../../generate-table/reducer'
import { regenerateSpreadsheetUrlAction } from './regenerate-spreadsheet-url'
import { generateSpreadsheetUrlAction } from './generate-spreadsheet-url'
import { CartItem } from '../types'
import { fetchCartAction } from './fetch-cart'
import { generateProposalAction } from './generate-proposal'

export const generateSpreadsheetAction = createAsyncThunk<
  {
    newCartItems: Array<CartItem>;
    spreadsheetUrl: string;
    spreadsheet: string;
    spreadsheetRub: string;
    pdf: string;
    doc: string;
  },
  {
    isWithSavingData: boolean;
    orderNumber: string;
    linkToTable: string;
    cartItems: Array<CartItem>;
  }
>(
  'cart/generateSpreadsheet',
  async ({
    isWithSavingData,
    orderNumber,
    linkToTable,
    cartItems,
  }, { dispatch }) => {
    let result = {
      spreadsheet: '',
      spreadsheetRub: '',
      pdf: '',
      doc: '',
    }

    dispatch(setIsProposalGenerationLoading(true))
    dispatch(setProposalGenerationWarnings([]))

    try {
      const spreadsheetUrl: string = isWithSavingData
        ? await dispatch(regenerateSpreadsheetUrlAction({
          orderNumber,
          linkToTable,
        })).unwrap()
        : await dispatch(generateSpreadsheetUrlAction({
          orderNumber,
          cartItems,
        })).unwrap()

      dispatch(setLinkToTableAction(spreadsheetUrl))

      const newCartItems = await dispatch(fetchCartAction({
        orderNumber,
      })).unwrap()

      if (newCartItems.length > 0) {
        result =
          await dispatch(generateProposalAction({
            orderNumber,
            spreadsheetLink: spreadsheetUrl,
          })).unwrap()
      }
      else {
        dispatch(setIsProposalGenerationLoading(false))
      }

      notification.success({
        message: 'Успешно',
        description: cartItems.length > 0
          ? 'Таблица успешно сгенерирована! Через несколько секунд будет сгенерировано коммерческое предложение.'
          : 'Таблица успешно сгенерирована! Добавьте товары вручную и нажмите "Перегенировать КП".',
      })

      return {
        newCartItems,
        spreadsheetUrl,
        ...result,
      }
    }
    catch (e) { }
    finally {
      dispatch(setIsProposalGenerationLoading(false))
      dispatch(setImportOrGenerateLoading(false))
    }

    return {
      newCartItems: [],
      spreadsheetUrl: '',
      ...result,
    }
  },
)
