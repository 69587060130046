import { createSlice } from '@reduxjs/toolkit'
import { ActionType } from '../types'
import { CartItem, CartState } from './types'
import { fetchCartAction } from './cart-async-actions'

const initialState: CartState = {
  cartItems: [],
  editedItem: undefined,
  openHandleEditor: false,
  addToCartBtnDisable: false,
  isLoading: false,
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart(state, action: ActionType<Array<CartItem>>) {
      state.cartItems = action.payload
    },

    setProductsListAction(state, action: ActionType<Array<CartItem>>) {
      state.cartItems = [...action.payload]
    },

    setEditedItemIndex(state, action: ActionType<number>) {
      state.editedItem = action.payload
    },

    setOpenHandleEditor(state, action: ActionType<boolean>) {
      state.openHandleEditor = action.payload
    },

    setAddToBtnDisable(state, action: ActionType<boolean>) {
      state.addToCartBtnDisable = action.payload
    },

    setIsCartLoading(state, action: ActionType<boolean>) {
      state.isLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCartAction.pending, state => {
        state.isLoading = true
      })
      .addCase(fetchCartAction.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(fetchCartAction.rejected, state => {
        state.isLoading = false
      })
  },
})

export const cartReducer = cartSlice.reducer
export const {
  setCart,
  setProductsListAction,
  setEditedItemIndex,
  setOpenHandleEditor,
  setAddToBtnDisable,
  setIsCartLoading,
} = cartSlice.actions
