import React, {
  MutableRefObject,
  ReactNode,
  useRef,
} from 'react'
import { SnipOptions, useSnip } from './hooks/useSnip'

export interface ReactSnipCallbacks {
  onJsClamp: (clamped: boolean) => void,
}

interface MultilineEllipsisProps extends Partial<SnipOptions> {
  children: ReactNode,
  callbacks?: ReactSnipCallbacks,
}

export function MultilineEllipsis({
  children,
  method,
  lines,
  midWord,
  ellipsis,
  callbacks,
}: MultilineEllipsisProps) {
  const ref = useRef<HTMLDivElement>(null)
  useSnip(ref as MutableRefObject<HTMLDivElement>, { method, lines, midWord, ellipsis }, callbacks)

  return <div ref={ref}>{children}</div>
}