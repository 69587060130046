import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getLocalStorageCallbacks } from '../../hooks/useLocalStorage/useLocalStorage'
import { OrderPosition } from '../../views/OrdersView/OrderPositions.utils'
import { ActionType } from '../types'

export interface PartialUpdateAction<O extends object, F extends keyof O> {
  field: F,
  value: O[F],
}

export type OrderPositionsTableGlobalFiltersData = {
  clientNameIds: Array<string>;
  nameIds: Array<string>;
  orderIds: Array<string>;
}

type OrderPositionsTableState = {
  orderPositions: Array<OrderPosition>;
  globalFilters: OrderPositionsTableGlobalFiltersData;
}

type SetOrdersAction = ActionType<Array<OrderPosition>>

function getInitialState(): OrderPositionsTableState {
  const storage = getStorageObject()

  return {
    orderPositions: [],
    globalFilters: storage?.globalFilters || {
      clientNameIds: [],
      nameIds: [],
      orderIds: [],
    },
  }
}

const {
  getStorageObject,
  setValueToStorage,
} = getLocalStorageCallbacks('orderPositionsStage')

const orderPositionsTableSlice = createSlice({
  name: 'order-positions-table',
  initialState: {...getInitialState()},
  reducers: {
    setOrderPositions(state, action: SetOrdersAction) {
      state.orderPositions = action.payload
    },
    setOrderPositionsTableGlobalFilter<T extends keyof OrderPositionsTableGlobalFiltersData>(
      state: OrderPositionsTableState,
      { payload }: PayloadAction<PartialUpdateAction<OrderPositionsTableGlobalFiltersData, T>>,
    ) {
      state.globalFilters[payload.field] = payload.value
      setValueToStorage('globalFilters', state.globalFilters)
    },
    resetPaginationIndex() {
      setValueToStorage('paginationPage', 0)
    },
  },
})

export const orderPositionsTableReducer = orderPositionsTableSlice.reducer
export const {
  setOrderPositions,
  setOrderPositionsTableGlobalFilter,
  resetPaginationIndex,
} = orderPositionsTableSlice.actions
