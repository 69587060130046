import React, { useEffect } from 'react'
import { Button, Card, Form, Input, Radio, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { getApi } from '../../api/getApi'
import { setEmailAction } from '../../store/auth/reducer'
import { PageHeader } from './page-header/index'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { APP_SCIENCE_EMAIL_KEY } from '../../constants/localStorage'
import { User } from '../../api/users/users'
import {ApiError} from '../../api/apiError/apiError'

const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 12,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 3,
    span: 12,
  },
}

export const AddUserView: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm<User>()

  useEffect(() => {
    const email = localStorage.getItem(APP_SCIENCE_EMAIL_KEY) ?? ''
    dispatch(setEmailAction(email))
  }, [dispatch])

  const addUser = (user: User) => {
    getApi()
      .users
      .addUser(user)
      .then(() => {
        notification.success({
          message: `Успешно`,
          description: 'Пользователь успешно добавлен',
        })
        setTimeout(() => { //TODO change to appropriate form clear
          history.go(0)
        }, 1000)
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при добавлении пользователей',
          description: error.getRuText?.() || error.message,
          duration: 10,
        })
        Sentry.captureException(error)
      })
  }

  return <>
    <PageHeader
      display
      title='Добавление пользователя'
    />
    <Card>
      <Form
        form={form}
        name='basic'
        {...layout}
        initialValues={{
          role: 'admin',
        }}
        onFinish={addUser}
      >
        <Form.Item
          label='Имя'
          name='first_name'
          rules={[
            {
              required: true,
              message: 'Введите имя пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Фамилия'
          name='last_name'
          rules={[
            {
              required: true,
              message: 'Введите фамилию пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Введите email пользователя!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Gmail'
          name='gmail'
          rules={[
            {
              message: 'Gmail должен быть либо корректным email, либо "-" (прочерк)',
              validator: (_, value) => {
                if (value === '-') {
                  return Promise.resolve()
                }

                if (EMAIL_REGEXP.test(value)) {
                  return Promise.resolve()
                }

                return Promise.reject('Введите либо gmail, либо "-" (прочерк)')
              },
            },
          ]}
        >
          <Input placeholder='gmail или "-" (прочерк)' />
        </Form.Item>

        <Form.Item
          label='Номер телефона'
          name='phone_number'
          rules={[
            {
              required: true,
              message: 'Введите номер телефона!',
            },
            {
              min: 12,
              max: 12,
              pattern: /^\+\d{11,15}$/,
              message: 'Введите телефон в формате +79201234567',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Роль'
          name='role'
          rules={[
            {
              required: true,
              message: 'Введите номер телефона!',
            },
          ]}
        >
          <Radio.Group>
            <Radio.Button
              value='admin'
            >
              Админ
            </Radio.Button>
            <Radio.Button
              value='user'
            >
              Пользователь
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...tailLayout}
        >
          <Button
            type='primary'
            htmlType='submit'
          >
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </>
}
