import React from 'react'
import { Drawer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCartItems,
  selectEditedItem,
  selectOpenHandleEditor,
} from '../../../../store/cart/cart.selectors'
import { HandleInputForm } from './handle-input-form/index'
import {
  setOpenHandleEditor,
  setProductsListAction,
} from '../../../../store/cart/cart.reducer'
import { useCartStorage } from '../cart/cart.storage'

export const SideHandleEditorContent: React.FC = () => {
  const { saveCart } = useCartStorage()

  const dispatch = useDispatch()
  const editedItemIndex = useSelector(selectEditedItem)
  const cartItems = useSelector(selectCartItems)
  const openHandleEditor = useSelector(selectOpenHandleEditor)

  const editedItem = editedItemIndex
    ? cartItems[editedItemIndex]
    : cartItems[0]

  const onClose = () => {
    dispatch(setOpenHandleEditor(false))
  }

  const onChangeHandler = (name: string, value: string) => {
    if (editedItemIndex == null) {
      return
    }

    const newCartItems = [...cartItems]
    newCartItems[editedItemIndex] = {
      ...cartItems[editedItemIndex],
      [name]: value,
    }

    dispatch(setProductsListAction(newCartItems))
    saveCart(newCartItems)
  }

  return (
    <Drawer
      title='Редактирование позиции'
      placement='right'
      className='side-handle-editor-content'
      destroyOnClose
      onClose={onClose} visible={openHandleEditor}
    >
      <div className='side-handle-editor-content'>
        <HandleInputForm
          //@ts-ignore
          initialValues={editedItem}
          onChangeHandler={onChangeHandler}
          showFinishBtn={false}
        />
      </div>
    </Drawer>
  )
}
