import { Button } from 'antd'
import { OpenLinkIcon, ProposalActionIcon } from '../../ProposalActionsIcon/ProposalActionsIcon'

type ButtonContentProps = {
  isButtonDisabled: boolean;
  link?: string;
  iconSrc: string;
  onClick?: () => void;
  message?: string;
}

export function ButtonContent({
  isButtonDisabled,
  link = '',
  iconSrc,
  onClick = () => { },
  message = '',
}: ButtonContentProps) {
  return (
    <Button
      block
      disabled={isButtonDisabled}
      onClick={onClick}
    >
      {link ? (
        <a
          href={link}
          target='_blank'
          className='d-flex align-items-center justify-content-center'
        >
          <ButtonContentInner
            iconSrc={iconSrc}
            message={message}
          />
        </a>
      ) : (
        <div className='d-flex align-items-center justify-content-center'>
          <ButtonContentInner
            iconSrc={iconSrc}
            message={message}
          />
        </div>
      )}
    </Button >
  )
}

type ButtonContentInnerProps = {
  iconSrc: string;
  message: string;
}

function ButtonContentInner({
  iconSrc,
  message,
}: ButtonContentInnerProps) {
  return (
    <>
      <div className='mr-1 d-flex align-items-center'>
        <OpenLinkIcon />
      </div>
      {message && (
        <span className='mr-1'>
          {message}
        </span>
      )}
      <ProposalActionIcon
        src={iconSrc}
      />
    </>
  )
}
