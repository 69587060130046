import { GlobalMultiSelect } from '@appscience/data-table'
import { checkNever } from '@appscience/utils'
import {SelectItem} from '@mantine/core/lib/components/Select/types'
import { uniqBy } from 'ramda'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import { setOrderPositionsTableGlobalFilter } from '../../../../../store/order-positions-table/reducer'
import { selectOrderPositionsTableGlobalFilter, selectTableOrderPositions } from '../../../../../store/order-positions-table/selectors'
import { OrderPosition } from '../../../OrderPositions.utils'
import { TableOrderPosition } from '../OrderPositionsTable.type'
import { mapOrderPositionToTableOrderPosition, mapToSelectItem } from '../OrderPositionsTable.utils'

type ClientNameData = TitleWithId<string> & {
  clientName: string;
}
type NameData = TitleWithId<string> & {
  name: string;
}
type OrderIdData = TitleWithId<string> & {
  orderId: number;
}

type OrderPositionsTableGlobalFiltersData = {
  clientNameIds: Array<ClientNameData>;
  nameIds: Array<NameData>;
  orderIds: Array<OrderIdData>;
}

type FilterType = keyof OrderPositionsTableGlobalFiltersData

function useTableData(): OrderPositionsTableGlobalFiltersData {
  const orderPositions: Array<OrderPosition> = useSelector(selectTableOrderPositions)
  const tableOrderPositions: TableOrderPosition[] = orderPositions
    .map(mapOrderPositionToTableOrderPosition)

  return {
    clientNameIds: uniqBy(
      ({clientName}) => clientName,
      tableOrderPositions,
    ).map(({id, clientName}) => ({
      // id,
      id: clientName,
      title: clientName,
      clientName,
    })),
    nameIds: uniqBy(
      ({name}) => name,
      tableOrderPositions,
    ).map(({id, name}) => ({
      // id,
      id: name,
      title: name,
      name,
    })),
    orderIds: uniqBy(
      ({orderId}) => orderId,
      tableOrderPositions,
    ).map(({id, orderId}) => ({
      // id,
      id: `${orderId}`,
      title: `${orderId}`,
      orderId,
    })),
  }
}

export function OrderPositionsTableGlobalFiltersSection() {
  const {clientNameIds, nameIds, orderIds} = useTableData()

  return (
    <div className='flex space-x-2 mr-1'>
      <Filter
        type='clientNameIds'
        data={clientNameIds.map(mapToSelectItem)}
      />
      <Filter
        type='nameIds'
        data={nameIds.map(mapToSelectItem)}
      />
      <Filter
        type='orderIds'
        data={orderIds.map(mapToSelectItem)}
      />
    </div>
  )
}

interface GlobalMultiSelectProps {
  type: FilterType,
  data: Array<SelectItem>,
}

function Filter({
  type,
  data,
}: GlobalMultiSelectProps) {
  const dispatch = useDispatch()
  const selectedIds: Array<string> = useSelector(selectOrderPositionsTableGlobalFilter(type))
  const resultData: Array<SelectItem> = [
    // { value: EMPTY_VALUE_ID, label: '(Empty value)' },
    ...data,
  ]

  return (
    <GlobalMultiSelect
      selected={selectedIds}
      data={resultData}
      onChange={ids => {
        dispatch(setOrderPositionsTableGlobalFilter({ field: type, value: ids }))
      }}
      label={<label className='text-xs'>{getLabel(type)}</label>}
      withinPortal={false}
    />
  )
}

function getLabel(type: FilterType) {
  switch (type) {
    case 'clientNameIds':
      return <FormattedMessage id='order_positions_table.columns.clientName' />
    case 'nameIds':
      return <FormattedMessage id='order_positions_table.columns.name' />
    case 'orderIds':
      return <FormattedMessage id='order_positions_table.columns.orderId' />
    default:
      checkNever(type)
      return ''
  }
}