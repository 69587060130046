import axios from 'axios'

export type SearchParamsType = {
  any?: string | number
  catalogue_id?: string | number
  cas_id?: string | number
  name?: string
  vendor?: string
}

export const CancelToken = axios.CancelToken
export const source = CancelToken.source()

export const axiosSuggestRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}

export const axiosSearchRequestData = {
  cancelToken: axios.CancelToken,
  source: source,
}
