import { GetOrderPositionsResponse, GetOrdersResponse } from './orders'

export const ORDER_POSITIONS_MOCK: GetOrderPositionsResponse = {
  error_reason: 'NoError',
  result: 'success',
  order_positions: [{
    id: 1,
    client_name: 'Нанолек',
    order_id: 13395565,
    name: 'Метанол Scharlayu Art. ME0315 1 000 МЛ',
    deadline: 1622720243,
    expected_delivery_date: 1622610243,
    status: 'arrived_to_hub',
    status_desc: 'Прибыло в хаб',
    cause_of_waiting: 'Форс-мажор',
    comment: 'Так получилось',
    last_status_date: '',
  }, {
    id: 2,
    client_name: 'Нанолек 2',
    order_id: 13395566,
    name: 'Mannitol Meets ACS Specifications GR ACS 3kg',
    deadline: 1653264000,
    expected_delivery_date: 1653134000,
    status: 'on_border',
    status_desc: 'Прибыло на таможню',
    cause_of_waiting: 'Форс-мажор',
    comment: 'Так получилось',
    last_status_date: '',
  }, {
    id: 3,
    client_name: 'Нанолек',
    order_id: 13395567,
    name: 'Метанол Scharlayu Art. ME0315 1 000 МЛ 2',
    deadline: 1643932800,
    expected_delivery_date: 1643702800,
    status: 'on_border',
    status_desc: 'Прибыло на таможню',
    cause_of_waiting: 'Форс-мажор',
    comment: 'Так получилось',
    last_status_date: '',
  }],
}

export const ORDERS_MOCK: GetOrdersResponse = {
  error_reason: 'NoError',
  result: 'success',
  orders: [{
    'id': 13395565,
    'name': 'вирусы, плазмиды, клетки',
    'status_group': 'active',
    'status': 'delivered',
    'payment_status': '',
    'price': '2504820',
    'bill_number': '987',
    'spec_number': '',
    'supply_date': '2022-04-26',
    'created_at': 1622720243,
    'approved_at': 1625529600,
    'supply_deadline': 1627948800,
    'supply_deadline2': 1629763200,
    'payment_deadline': 1653264000,
    'pay_condition_code': 'part_prepaid',
    'pay_condition_desc': 'Частичная предоплата',
    'seller_company_name': 'Сайнс',
    'seller_company_code': 'science',
    'money_received': 2407060,
    'delivery_city': 'Ярославль',
    'responsible_user_name': '',
  }, {
    'id': 16784731,
    'name': 'наборы cygnus | 10178031, 10136336  №36',
    'status_group': 'active',
    'status': 'in_progress',
    'payment_status': '',
    'price': '3698064',
    'bill_number': '216',
    'spec_number': '',
    'supply_date': '',
    'created_at': 1642781320,
    'approved_at': 1643932800,
    'supply_deadline': 1646352000,
    'supply_deadline2': 0,
    'payment_deadline': 0,
    'pay_condition_code': 'part_prepaid',
    'pay_condition_desc': 'Частичная предоплата',
    'seller_company_name': 'Лайф Сайнс',
    'seller_company_code': 'lifescience',
    'money_received': 0,
    'delivery_city': '',
    'responsible_user_name': '',
  }, {
    'id': 16739477,
    'name': 'L-Метионин сульфоксимин',
    'status_group': 'active',
    'status': 'delivered',
    'payment_status': '',
    'price': '100613',
    'bill_number': '2251',
    'spec_number': '',
    'supply_date': '2022-02-09',
    'created_at': 1642516732,
    'approved_at': 1642550400,
    'supply_deadline': 1644364800,
    'supply_deadline2': 0,
    'payment_deadline': 1646265600,
    'pay_condition_code': 'part_prepaid',
    'pay_condition_desc': 'Частичная предоплата',
    'seller_company_name': 'Сайнс',
    'seller_company_code': 'science',
    'money_received': 50306,
    'delivery_city': '',
    'responsible_user_name': '',
  }],
}