import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCitiesAction } from '../../../../store/search/reducer'
import { selectCartItems } from '../../../../store/cart/cart.selectors'
import {
  selectDocLink,
  selectGoogleSheetsLink,
  selectImportOrGenerateLoading,
  selectLinkToTable,
  selectOrderNumber,
  selectPdfLink,
  selectPipeline,
  selectRubGoogleSheetsLink,
  selectUsdGoogleSheetsLink,
} from '../../../../store/generate-table/selectors'
import {
  setImportOrGenerateLoading,
} from '../../../../store/generate-table/reducer'
import { useCart } from '../cart/cart.hook'
import { useLocalStorage } from '../../../../hooks/useLocalStorage/useLocalStorage'
import { attachProposalAction, generateBillAction, generateProposalAction, generateSpecificationAction, generateSpreadsheetAction, importTableAction } from '../../../../store/cart/cart-async-actions'
import { useThunkDispatch } from '../../../../store/use-thunk-dispatch'
import { selectIsProposalGenerationLoading, selectProposalGenerationWarnings } from '../../../../store/proposal-generation'
import { useCartStorage } from '../cart/cart.storage'
import { PipelineEnum } from '../../../../api/proposal/proposal'

export function useProposalGeneration() {
  const dispatch = useDispatch()
  const dispatchThunk = useThunkDispatch()
  const { initState } = useCart()
  const { setValueToStorage } = useLocalStorage('proposalGenerationStage')

  const pdfLink = useSelector(selectPdfLink)
  const docLink = useSelector(selectDocLink)
  const orderNumber = useSelector(selectOrderNumber)
  const cartItems = useSelector(selectCartItems)
  const linkToTable = useSelector(selectLinkToTable)
  const importOrGenerateLoading = useSelector(selectImportOrGenerateLoading)
  const googleSheetsLink: string = useSelector(selectGoogleSheetsLink)
  const rubGoogleSheetsLink: string = useSelector(selectRubGoogleSheetsLink)
  const usdGoogleSheetsLink: string = useSelector(selectUsdGoogleSheetsLink)
  const pipeline = useSelector(selectPipeline)
  const isSupplyPipeline = pipeline === PipelineEnum.Supply

  const isLoading = useSelector(selectIsProposalGenerationLoading)
  const warnings = useSelector(selectProposalGenerationWarnings)

  const isOrderNumberEmpty = !orderNumber?.trim()?.length
  const isGenerationButtonDisabled: boolean = isLoading || isOrderNumberEmpty
  const isNewProductsAttachButtonDisabled =
    isGenerationButtonDisabled
    || !linkToTable
    || !googleSheetsLink
  const isAttachButtonDisabled: boolean =
    isSupplyPipeline
      ? (
        isNewProductsAttachButtonDisabled
        || !docLink
        || !pdfLink
        || !rubGoogleSheetsLink
        || !usdGoogleSheetsLink
      )
      : isNewProductsAttachButtonDisabled

  useEffect(() => {
    dispatch(getCitiesAction())
  }, [dispatch])

  const { saveCart } = useCartStorage()

  const generateProposal = useCallback(async (spreadsheetLink: string) => {
    const { spreadsheet, spreadsheetRub, spreadsheetUsd, pdf, doc } =
      await dispatchThunk(generateProposalAction({
        orderNumber,
        spreadsheetLink,
      })).unwrap()

    setValueToStorage('spreadsheet', spreadsheet)
    setValueToStorage('spreadsheetRub', spreadsheetRub)
    setValueToStorage('spreadsheetUsd', spreadsheetUsd)
    setValueToStorage('pdf', pdf)
    setValueToStorage('doc', doc)
  }, [dispatchThunk, orderNumber, setValueToStorage])

  const generateSpreadsheet = useCallback(async (isWithSavingData: boolean) => {
    const {
      newCartItems,
      spreadsheetUrl,
      spreadsheet,
      spreadsheetRub,
      pdf,
      doc,
    } = await dispatchThunk(generateSpreadsheetAction({
      isWithSavingData,
      orderNumber,
      linkToTable,
      cartItems,
    })).unwrap()

    saveCart(newCartItems)
    setValueToStorage('linkToTable', spreadsheetUrl)
    setValueToStorage('spreadsheet', spreadsheet)
    setValueToStorage('spreadsheetRub', spreadsheetRub)
    setValueToStorage('pdf', pdf)
    setValueToStorage('doc', doc)
  }, [cartItems, dispatchThunk, linkToTable, orderNumber, saveCart, setValueToStorage])

  const generateBill = useCallback(async () => {
    const billPdfUrl = await dispatchThunk(generateBillAction({
      orderNumber,
    })).unwrap()

    setValueToStorage('bill', billPdfUrl)
  }, [dispatchThunk, orderNumber, setValueToStorage])

  const importTable = useCallback(async (parentDealId: number) => {
    const importTablePdfUrl = await dispatchThunk(importTableAction({
      parentDealId,
      orderNumber,
    })).unwrap()

    setValueToStorage('linkToTable', importTablePdfUrl)

    await Promise.resolve(() => setTimeout(initState, 700))
    dispatch(setImportOrGenerateLoading(false))
  }, [dispatch, dispatchThunk, initState, orderNumber, setValueToStorage])

  const generateSpecification = useCallback(async () => {
    const { specificationPdfUrl, specificationDocUrl } =
      await dispatchThunk(generateSpecificationAction({
        orderNumber,
        linkToTable,
      })).unwrap()

    setValueToStorage('specificationPdf', specificationPdfUrl)
    setValueToStorage('specificationDoc', specificationDocUrl)
  }, [dispatchThunk, linkToTable, orderNumber, setValueToStorage])

  const attachProposal = useCallback(() => {
    dispatchThunk(attachProposalAction({
      orderNumber,
      proposalDocUrl: docLink,
      proposalPdfUrl: pdfLink,
      spreadsheetUrl: linkToTable,
      proposalSpreadsheetUrl: googleSheetsLink,
      proposalSpreadsheetRubUrl: rubGoogleSheetsLink,
      proposalSpreadsheetUsdUrl: usdGoogleSheetsLink,
    }))
  }, [dispatchThunk, orderNumber, docLink, pdfLink, linkToTable, googleSheetsLink, rubGoogleSheetsLink, usdGoogleSheetsLink])

  return {
    isLoading,
    isGenerationButtonDisabled,
    generateSpreadsheet,
    importTable,
    linkToTable,
    importOrGenerateLoading,
    isAttachButtonDisabled,
    warnings,
    generateProposal,
    generateBill,
    generateSpecification,
    attachProposal,
  }
}
