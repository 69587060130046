import { LoadingOverlay } from '@mantine/core'
import { DatePicker, notification } from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import MetabaseEmbeddingService, { GetMetabaseEmbeddingUrlResponse } from '../../api/metabase-embedding/metabase-embedding'
import styles from './BonusesView.module.css'
import * as Sentry from '@sentry/react'
import {ApiError} from '../../api/apiError/apiError'

export function BonusesView() {
  const [dashboardLink, setDashboardLink] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState<moment.Moment>(moment(new Date()))
  const onDateChange = useCallback((momentDate: moment.Moment | null) => {
    setDate(momentDate ?? moment())
  }, [])

  useEffect(() => {
    setLoading(true)

    MetabaseEmbeddingService
      .getUrl('', date)
      .then(({ data: url }: GetMetabaseEmbeddingUrlResponse) => {
        setDashboardLink(url)
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Error',
          description: error.getRuText?.() || `Metabase embedding url: ${error.message}`,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [date])

  if (isLoading || !dashboardLink) {
    return (
      <div className={styles['loading']}>
        <LoadingOverlay
          visible
          zIndex={0}
        />
      </div>
    )
  }

  return (
    <div
      className={styles['root']}
    >
      <div
        className={styles['header']}
      >
        <DatePicker
          picker='month'
          format='MMMM, YYYY'
          allowClear={false}
          value={date}
          onChange={onDateChange}
          className={styles['month-picker']}
        />
      </div>
      <div
        className={styles['iframe-container']}
      >
        <iframe
          src={dashboardLink}
          frameBorder='0'
          width='100%'
          height='100%'
          className={styles['iframe']}
        ></iframe>
      </div>
    </div>
  )
}
