import { ProviderStatusEnum } from '../../../../../../store/search/types'
import cn from 'classnames'
import styles from './providers.module.css'

export type Provider = {
  name: string;
  status: ProviderStatusEnum;
}

type ProvidersProps = {
  providers: Array<Provider>;
}

export function Providers({
  providers,
}: ProvidersProps) {
  return (
    <div>
      {providers.map(({ name, status }) => (
        <div
          className={cn(
            styles['provider'],
            {
              'text-success': status === ProviderStatusEnum.Ok,
              'text-warning': status === ProviderStatusEnum.Freezed,
              'text-danger': status === ProviderStatusEnum.Blocked,
            },
          )}
        >
          <span
            className={styles['provider__name']}
          >
            {name}
          </span>
          {status}
        </div>
      ))}
    </div>
  )
}
