import { useLocalStorage } from '../../../../hooks/useLocalStorage/useLocalStorage'
import { CartItem } from '../../../../store/cart/types'

export function useCartStorage() {
  const {
    getStorageValue,
    setValueToStorage,
    removeValueFromStorage,
  } = useLocalStorage('cart')

  function getSavedCart(): Array<CartItem> {
    try {
      return getStorageValue('items') || []
    }
    catch (error) {
      return []
    }
  }

  function saveCart(cartItems: Array<CartItem>): void {
    try {
      setValueToStorage('items', cartItems || [])
    }
    catch (error) {
      setValueToStorage('items', [])
    }
  }

  function clearSavedCart(): void {
    removeValueFromStorage('items')
  }

  return {
    getSavedCart,
    saveCart,
    clearSavedCart,
  }
}
