import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'

// TODO api typing
export const auth = {
  login: (userData: AnyType): Promise<AnyType> =>
    apiCreator
      .post(
        `${API_URL.AUTH}/login`,
        {
          ...userData,
        },
        {
          headers: { 'public-request': 'true' },
        })
      .then(res => res),
  check: (): Promise<AnyType> =>
    apiCreator.get(
      `${API_URL.AUTH}/check`,
    )
      .then(res => res),
}
