import React, { useMemo } from 'react'
import moment from 'moment'
import { Button, Card, Col, Row, Typography } from 'antd'
import { ShoppingCartOutlined, WarningOutlined } from '@ant-design/icons'
import { ProductType } from '../../../../../store/search/types'
import 'moment/locale/ru'
import parse from 'html-react-parser'
import { hasCountryIcon } from '../../../../../utils/country'
import TopProductProperties from './top-product-properties'
import { Provider } from '../common/providers/providers'
import ProvidersStatusTooltip from '../common/providers-status-tooltip'

const { Title, Text } = Typography

type ProductCardType = ProductType & {
  addToCart: () => void;
  isDisabledBtn?: boolean;
}

export const ProductCard: React.FC<ProductCardType> = props => {
  const {
    name,
    catalogue_id,
    vendor,
    cas_id,
    partner_url,
    shipping_conditions,
    parse_time,
    addToCart,
    price,
    price_currency,
    package_size,
    isDisabledBtn,
    availability_flag,
    hazmat,
    country_of_purchase,
    brand,
    vendor_availability,
  } = props

  const countryOfPurchase: string = (hasCountryIcon(country_of_purchase) && country_of_purchase) || ''

  const dateTransformer = () => {
    moment.locale()
    const date = moment(parse_time)
    return date.fromNow()
  }
  const updateTimeTooltipMessage = `Последнее обновление ${dateTransformer()}`

  const providers: Array<Provider> = useMemo(
    () =>
      Object
        .entries(vendor_availability?.providers_statuses ?? {})
        .map(([providerName, status]) => ({
          name: providerName,
          status,
        })),
    [vendor_availability?.providers_statuses],
  )
  const isProviderAvailable: boolean = vendor_availability?.availability_flag ?? false

  return (
    <Card
      className={'product-card'}
    >
      <div className='card-holder'>
        <Row
          justify='space-between'
          className='mb-1'
        >
          <Col
            xs={12}
            sm={16}
            md={18}
            lg={9}
            xl={18}
          >
            <Title
              level={4}
              ellipsis={{
                rows: 3,
              }}
              className='m-0'
            >
              <ProvidersStatusTooltip
                isProviderAvailable={isProviderAvailable}
                providers={providers}
              />
              <a
                href={partner_url}
                target='_blank'
                className='text-dark'
              >
                {parse(`<span>${name}</span>`)}
              </a>
            </Title>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={11}
            xl={0}
          >
            <Row
              align='middle'
              justify='end'
              style={{ height: '100%' }}
            >
              <TopProductProperties
                brand={brand}
                countryOfPurchase={countryOfPurchase}
                isAvailable={availability_flag}
                updateTimeTooltipMessage={updateTimeTooltipMessage}
                vendor={vendor}
              />
            </Row>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={4}
            xl={6}
            className='justify-content-between justify-content-md-end d-flex align-items-center'
          >
            <Button
              disabled={isDisabledBtn}
              type='primary'
              ghost
              className='ml-3'
              onClick={addToCart}
            >
              Добавить <ShoppingCartOutlined style={{ fontSize: 17, transform: 'translateY(2px)' }} />
            </Button>
          </Col>
        </Row>

        <Row
          className='mb-3'
        >
          <Col
            sm={24}
            md={24}
            lg={0}
            xl={24}
          >
            <Row
              align='middle'
            >
              <TopProductProperties
                brand={brand}
                countryOfPurchase={countryOfPurchase}
                isAvailable={availability_flag}
                updateTimeTooltipMessage={updateTimeTooltipMessage}
                vendor={vendor}
              />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24} sm={12} md={10}>
            <Row>
              <Col span={24}>
                {catalogue_id && (
                  <Text type='secondary' style={{ fontWeight: 400 }} className='mr-2 d-block mb-2'>
                    <span className='mr-2'>
                      Catalogue id:
                    </span>
                    <b className='text-dark'>
                      {catalogue_id}
                    </b>
                  </Text>
                )}
                {shipping_conditions && (
                  <Text type='secondary' style={{ fontWeight: 400 }} className='mr-2 d-block mb-2'>
                    <span className='mr-2'>
                      Темп. реж:
                    </span>
                    <b className='text-dark'>
                      {shipping_conditions}
                    </b>
                  </Text>
                )}
              </Col>
            </Row>
          </Col>

          <Col
            span={24}
            sm={12}
            md={6}
          >
            <Row>
              <Col
                span={24}
                className='pl-0'
              >
                {cas_id && (
                  <Text
                    type='secondary'
                    style={{ fontWeight: 400 }}
                    className='mr-2 d-flex mb-2'
                  >
                    <span className='mr-2 ml-0 ml-md-3'>
                      CAS:
                    </span>
                    <b className='text-dark'>
                      {cas_id}
                    </b>
                  </Text>
                )}
                {hazmat && (
                  <Text
                    type='secondary'
                    style={{ fontWeight: 400 }}
                    className='mr-2 d-flex mb-2 pl-3'
                  >
                    <b className='text-dark'>
                      <WarningOutlined
                        className='text-warning mr-1'
                        style={{ fontSize: 16 }}
                      />
                      Hazmat
                    </b>
                  </Text>
                )}
              </Col>
            </Row>
          </Col>

          {/* Desktop  */}
          <Col
            span={24}
            md={8}
            className='text-left text-md-right d-none d-md-block'
          >
            <Row>
              <Col
                span={24}
              >
                <Text
                  type='secondary'
                  style={{ fontWeight: 400 }}
                  className='mr-2 d-block mb-2'
                >
                  <span className='mr-2 ml-0 ml-md-3'>
                    Цена:
                  </span>
                  <b className='text-dark'>
                    {price} {price_currency}
                  </b>
                </Text>
                {package_size && (
                  <Text
                    type='secondary'
                    style={{ fontWeight: 400 }}
                    className='mr-2 d-block mb-2'
                  >
                    <span className='mr-2'>
                      Фасовка:
                    </span>
                    <b className='text-dark'>
                      {package_size}
                    </b>
                  </Text>
                )}
              </Col>
            </Row>
          </Col>

          {/* Mobile */}
          <Col
            span={24}
            className='d-md-none'
          >
            <Row>
              <Col
                span={24}
                sm={12}
              >
                <Text
                  type='secondary'
                  style={{ fontWeight: 400 }}
                  className='mr-2 d-block mb-2'
                >
                  <span className='mr-2 ml-0 ml-md-3'>
                    Цена:
                  </span>
                  <b className='text-dark'>
                    {price} {price_currency}
                  </b>
                </Text>
              </Col>
              <Col
                span={24}
                sm={12}
              >
                {package_size && (
                  <Text
                    type='secondary'
                    style={{ fontWeight: 400 }}
                    className='mr-2 d-block mb-2'
                  >
                    <span className='mr-2'>
                      Фасовка:
                    </span>
                    <b className='text-dark'>
                      {package_size}
                    </b>
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  )
}
