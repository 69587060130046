import { Button, Col } from 'antd'
import { ProposalActionsButton } from '../ProposalActionsButton'
import { useSelector } from 'react-redux'
import { selectDocLink, selectGoogleSheetsLink, selectPdfLink, selectPipeline, selectRubGoogleSheetsLink, selectUsdGoogleSheetsLink } from '../../../../../../store/generate-table/selectors'
import googleSheetsIconSrc from '../../../../../../assets/img/google-sheets-icon.png'
import pdfIconSrc from '../../../../../../assets/svg/pdf-icon.svg'
import googleDocsIconSrc from '../../../../../../assets/svg/google-docs-icon.svg'
import { PipelineEnum } from '../../../../../../api/proposal/proposal'

type ProposalGenerationActionProps = {
  isGenerationButtonDisabled: boolean;
  generateProposal: () => void;
}

export function ProposalGenerationAction({
  isGenerationButtonDisabled,
  generateProposal,
}: ProposalGenerationActionProps) {
  const googleSheetsLink: string = useSelector(selectGoogleSheetsLink)
  const rubGoogleSheetsLink: string = useSelector(selectRubGoogleSheetsLink)
  const usdGoogleSheetsLink: string = useSelector(selectUsdGoogleSheetsLink)
  const pdfLink = useSelector(selectPdfLink)
  const docLink = useSelector(selectDocLink)
  const pipeline = useSelector(selectPipeline)
  const isSupplyPipeline = pipeline === PipelineEnum.Supply

  return (
    <>
      <Col
        span='7'
      >
        <Button
          type='primary'
          block
          className='mb-4'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={isGenerationButtonDisabled}
          onClick={generateProposal}
        >
          Сгенерировать КП
        </Button>
      </Col>

      <Col
        span='5'
        className='pl-2'
      >
        <ProposalActionsButton
          link={googleSheetsLink}
          iconSrc={googleSheetsIconSrc}
          message={isSupplyPipeline ? 'RUB & USD' : 'УЕ'}
        />
      </Col>

      {!isSupplyPipeline && (
        <Col
          span='12'
        >
        </Col>
      )}

      {isSupplyPipeline && (
        <>
          <Col
            span='4'
            className='pl-2'
          >
            <ProposalActionsButton
              link={rubGoogleSheetsLink}
              iconSrc={googleSheetsIconSrc}
              message='RUB'
            />
          </Col>
          <Col
            span='4'
            className='pl-2'
          >
            <ProposalActionsButton
              link={usdGoogleSheetsLink}
              iconSrc={googleSheetsIconSrc}
              message='USD'
            />
          </Col>
          <Col
            span='2'
            className='pl-2'
          >
            <ProposalActionsButton
              link={pdfLink}
              iconSrc={pdfIconSrc}
            />
          </Col>
          <Col
            span='2'
            className='pl-2'
          >
            <ProposalActionsButton
              link={docLink}
              iconSrc={googleDocsIconSrc}
            />
          </Col>
        </>
      )}
    </>
  )
}
