import { createAsyncThunk } from '@reduxjs/toolkit'
import { getApi } from '../../../api/getApi'
import { GetProposalResponse } from '../../../api/proposal/proposal'
import { ApiError } from '../../../api/apiError/apiError'
import { notification } from 'antd'
import { ApiErrorCodeEnum } from '../../../api/apiError/apiErrorCode.enum'
import * as Sentry from '@sentry/react'
import { setCart } from '../cart.reducer'
import { CartItem } from '../types'

export const fetchCartAction = createAsyncThunk<
  Array<CartItem>,
  { orderNumber: string }
>(
  'cart/fetchCart',
  async ({ orderNumber }, { dispatch }) => {
    try {
      const response: GetProposalResponse = await getApi()
        .proposal
        .getProposalData({
          id: orderNumber,
        })

      const cartItems = (response?.items ?? [])
        .map(item => ({
          ...item,
          need_analogues: false,
        }))

      dispatch(setCart(cartItems))
      return cartItems
    }
    catch (e) {
      const error = e as ApiError
      if (error instanceof ApiError && error?.getCode() === ApiErrorCodeEnum.get_amo_deal) {
        notification.error({
          message: `Ошибка при получении данных КП`,
          description: `Сделка с номером ${orderNumber} не найдена`,
          duration: 5,
        })
      }
      else {
        notification.error({
          message: 'Ошибка при получении данных КП',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
      }

      Sentry.captureException(error)
    }

    return []
  },
)
