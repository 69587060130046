import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../types'
import { OrderPositionsTableGlobalFiltersData } from './reducer'

const selectOrderPositionsTable = (state: RootState) => state.orderPositionsTable

export const selectTableOrderPositions = createSelector(selectOrderPositionsTable, state => state.orderPositions)

export const selectOrderPositionsTableGlobalFilter =
  <T extends keyof OrderPositionsTableGlobalFiltersData>(field: T) =>
    createSelector(
      selectOrderPositionsTable, state => state.globalFilters[field],
    )

export const selectOrderPositionsTableGlobalFilters = () => createSelector(
  selectOrderPositionsTable, state => state.globalFilters,
)
