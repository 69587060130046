import { API_URL } from '../../constants/environment'
import axiosInstance from '../apiCreator'
import { ORDER_POSITIONS_MOCK } from './orderPositionsMock'
import { TWIN_USER_ITEMS_RESPONSE_MOCK } from './twinUserItemsMock'

export type OrderResponse = {
  approved_at: number;
  bill_number: string;
  created_at: number;
  delivery_city: string;
  id: number;
  money_received: number;
  name: string;
  pay_condition_code: string;
  pay_condition_desc: string;
  payment_deadline: number;
  payment_status: string;
  price: string;
  responsible_user_name: string;
  seller_company_code: string;
  seller_company_name: string;
  spec_number: string;
  status: string;
  status_group: string;
  supply_date: string;
  supply_deadline: number;
  supply_deadline2: number;
}

export type GetOrdersResponse = {
  result: string;
  error_reason: string;
  orders: Array<OrderResponse>;
}

export type OrderItemResponse = {
  bill_position: number;
  brand: string;
  catalogue_id: string;
  cost: number;
  count: number;
  deadline: string;
  express: boolean;
  id: number;
  name: string;
  original_name: string;
  price: number;
  provider_exp: string;
  provider_lot: string;
  status: string;
  status_desc: string;
  sum: number;
  supply_country: string;
  supply_store: string;
  last_status_date: string;
  expected_delivery_date: string;
}

export type GetOrderResponse = {
  result: string;
  error_reason: string;
  bill_pdf_url: string;
  items: Array<OrderItemResponse>;
  order: OrderResponse;
  proposal_pdf_url: string;
}

export type OrderPositionResponse = {
  id: number;
  client_name: string;
  order_id: number;
  name: string;
  deadline: number;
  expected_delivery_date: number;
  status: string;
  status_desc: string;
  last_status_date: string;
  cause_of_waiting: string;
  comment: string;
}

export type GetOrderPositionsResponse = {
  result: string;
  error_reason: string;
  order_positions: Array<OrderPositionResponse>;
}

export type TwinUserItemResponse = {
  client_name: string;
  deadline_date: string;
  deal_id: number;
  delay_reason: string;
  expected_delivery_date: string;
  id: number;
  name: string;
  prt_comments: string;
  sales_comments: string;
  sd_comments: string;
  status: string;
  status_description: string;
  last_status_date: string;
}

export type GetTwinUserItemsResponse = {
  result: string;
  error_reason: string;
  items: Array<TwinUserItemResponse>;
}

export type SaveSalesCommentsRequest = {
  id: number;
  sales_comments: string;
}

export type SaveSalesCommentsResponse = Record<string, never>;

type OrderServiceType = {
  getOrders: () => Promise<GetOrdersResponse>;
  getOrder: (id: string) => Promise<GetOrderResponse>
  getOrderPositionsMock: () => Promise<GetOrderPositionsResponse>;
  getTwinUserItems: () => Promise<GetTwinUserItemsResponse>;
  getTwinUserItemsMock: () => Promise<GetTwinUserItemsResponse>;
  saveSalesComments: (twinUserItemId: number, salesComments: string) => Promise<SaveSalesCommentsResponse>;
}

const OrdersService: OrderServiceType = {
  getOrders: () =>
    axiosInstance.get<never, GetOrdersResponse>(
      `${API_URL.CUSTOMER_AREA}/orders`,
    ),
  getOrder: (id: string) =>
    axiosInstance.get<never, GetOrderResponse>(
      `${API_URL.CUSTOMER_AREA}/get-order`,
      {
        params: {
          id,
        },
      },
    ),
  getOrderPositionsMock: () => new Promise(resolve => {
    setTimeout(() => {
      resolve(ORDER_POSITIONS_MOCK)
    }, 2000)
  }),
  getTwinUserItems: () =>
    axiosInstance.get<GetTwinUserItemsResponse>(
      `${API_URL.TWIN}/user-items`,
    ).then(res => res.data),
  getTwinUserItemsMock: () => new Promise(resolve => {
    setTimeout(() => {
      resolve(TWIN_USER_ITEMS_RESPONSE_MOCK)
    }, 2000)
  }),
  saveSalesComments: (twinUserItemId: number, salesComments: string) =>
    axiosInstance.post<never, SaveSalesCommentsResponse, SaveSalesCommentsRequest>(
      `${API_URL.TWIN}/save-sales-comments`,
      {
        id: twinUserItemId,
        sales_comments: salesComments,
      },
    ),
}

export default OrdersService
