import { API_URL } from '../../constants/environment'
import apiCreator from '../apiCreator'

type AvailabilityOption = {
  available_now: boolean;
  excel_comment: string;
  not_applicable: boolean;
  parsed_date: string;
  quantity: string;
  shipment_date: string;
}

export type GetProductAvailabilityResponse = {
  availability_options: Array<AvailabilityOption>;
  availability_update_time: string;
  available: boolean;
  cache_used: boolean;
  excel_comment: string;
}

type AvailabilityApi = {
  getProductAvailability: (productId: string, quantity: number) => Promise<GetProductAvailabilityResponse>,
}

export const availabilityApi: AvailabilityApi = {
  getProductAvailability: (productId: string, quantity: number): Promise<GetProductAvailabilityResponse> =>
    apiCreator
      .post<GetProductAvailabilityResponse>(
        `${API_URL.AVAILABILITY}/get_updated`,
        {
          product_id: productId,
          quantity,
        },
      )
      .then(({ data }) => data),
}
