import { SelectItem } from '@mantine/core'
import { OrderPosition, mapOrderItemStatusToMessage, unixTimestampToFormattedDate } from '../../OrderPositions.utils'
import { TableOrderPosition, TableOrderPositionColumnId } from './OrderPositionsTable.type'

export function mapOrderPositionToTableOrderPosition(orderPosition: OrderPosition): TableOrderPosition {
  return {
    ...orderPosition,
    id: `${orderPosition.id}`,
  }
}

export function mapToSelectItem<ID extends string>(data: TitleWithId<ID>): SelectItem {
  return {value: data.id, label: data.title}
}

export function mapOrderPositionPropertyToCSV(
  orderPosition: TableOrderPosition,
  columnId: TableOrderPositionColumnId,
): string {
  switch (columnId) {
    case 'clientName':
    case 'orderId':
    case 'id':
    case 'name':
    case 'comment':
      return `${orderPosition[columnId] || '-'}`
    case 'status':
      return mapOrderItemStatusToMessage(
        orderPosition?.status,
        orderPosition?.statusDescription,
        orderPosition?.lastStatusDate,
      )
    case 'procurementComment':
      return serializeOrderPositionComments(orderPosition) || '-'
    case 'deadline':
    case 'expectedDeliveryDate':
      return unixTimestampToFormattedDate(orderPosition[columnId])
    default:
      return ''
  }
}

function serializeOrderPositionComments(orderPosition: TableOrderPosition): string {
  if (!orderPosition?.procurementComment && !orderPosition?.sdComment) {
    return ''
  }

  if (!orderPosition?.procurementComment) {
    return `SD: "${orderPosition?.sdComment}"`
  }

  if (!orderPosition?.sdComment) {
    return `Procurement: "${orderPosition?.procurementComment}"`
  }

  return `Procurement: "${orderPosition?.procurementComment}" | SD: "${orderPosition?.sdComment}"`
}
