import {ProductType} from '../../../../store/search/types'

export const isFilteredWord = (item: ProductType, trimString: string) => {
  let status = false
  //TODO:improvements
  Object.keys(item).forEach(() => {
    if (
      (item.catalogue_id.toUpperCase().indexOf(trimString) !== -1)
      || (item.shipping_conditions.toUpperCase().indexOf(trimString) !== -1)
      || (item.cas_id.toUpperCase().indexOf(trimString) !== -1)
      || (`${item.price}`.indexOf(trimString) !== -1)
      || (item.price_currency.toUpperCase().indexOf(trimString) !== -1)
      || (item.availability.toUpperCase().indexOf(trimString) !== -1)
      || (item.package_size.toUpperCase().indexOf(trimString) !== -1)
      || (item.vendor.toUpperCase().indexOf(trimString) !== -1)
      || (item.un_id.toUpperCase().indexOf(trimString) !== -1)
      || (item.name.toUpperCase().indexOf(trimString) !== -1)
      || (item.shipping_group_id.toUpperCase().indexOf(trimString) !== -1)
    ) {
      status = true
    }
  })
  return status
}