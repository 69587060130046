import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import OrdersService, { GetTwinUserItemsResponse } from '../../api/orders/orders'
import { setOrderPositions } from '../../store/order-positions-table/reducer'
import { OrderPositionsTable } from './components/OrdersTable/OrderPositionsTable'
import { Loading } from './components/Loading/Loading'
import { NotLinked } from './components/NotLinked/NotLinked'
import { OrderPosition, mapTwinUserItemResponseToOrderPosition } from './OrderPositions.utils'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import {ApiError} from '../../api/apiError/apiError'

export function OrderPositionsView() {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [isNotLinked, setNotLinked] = useState(false)

  const getOrderPositions = useCallback(() => {
    setLoading(true)
    dispatch(setOrderPositions([]))

    OrdersService
      .getTwinUserItems()
      .then((response: GetTwinUserItemsResponse) => {
        if (response?.result === 'error' && response?.error_reason === 'NotLinkedToCompany') {
          setNotLinked(true)
          return
        }

        const orderPositions: Array<OrderPosition> = (response?.items ?? [])
          ?.map(mapTwinUserItemResponseToOrderPosition) ?? []
        dispatch(setOrderPositions(orderPositions))
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при получении данных о позициях',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  useEffect(() => {
    getOrderPositions()
  }, [getOrderPositions])

  return (
    <>
      {isLoading && (
        <Loading cover='content' />
      )}

      {isNotLinked && (
        <NotLinked />
      )}

      {!isLoading && !isNotLinked && (
        <div className='erp-table p-2'>
          <OrderPositionsTable />
        </div>
      )}
    </>
  )
}
