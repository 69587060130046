import styles from './PaymentsView.module.css'

export function PaymentsView() {
  return (
    <iframe
      src='https://analytics.appscience.ru/public/dashboard/f6ec5899-3eda-4cfd-8273-eb8b3e31a7ba'
      frameBorder='0'
      width='100%'
      height='100%'
      className={styles['iframe']}
    ></iframe>
  )
}
