import React, { useState } from 'react'
import { Alert, Button, Form, Input, notification } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { getApi } from '../../../api/getApi'
import { AUTH_TOKEN } from '../../../config/AppConfig'
import { setEmailAction } from '../../../store/auth/reducer'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { APP_SCIENCE_EMAIL_KEY } from '../../../constants/localStorage'
import {ApiError} from '../../../api/apiError/apiError'

type UserDataType = {
  email: string
  password: string
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const LoginForm = (props: AnyType) => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const [message] = useState<string>('')
  const [showMessage] = useState<boolean>(false)
  const query = useQuery()
  const dispatch = useDispatch()

  const {
    showForgetPassword,
    onForgetPasswordClick,
    extra,
  } = props

  const initialCredential = {
    email: '',
    password: '',
  }

  const onLogin = (values: UserDataType) => {
    setLoading(true)
    const {
      email,
      password,
    } = values

    getApi()
      .auth
      .login({
        password,
        email,
      })
      .then(({ data }) => {
        dispatch(setEmailAction(email))
        localStorage.setItem(APP_SCIENCE_EMAIL_KEY, email)
        localStorage.setItem(AUTH_TOKEN, data.auth_token)
        const redirect = query.get('redirect')
        history.push(redirect ? redirect : '/proposal')
      })
      .catch((error: ApiError) => {
        notification.error({
          message: 'Ошибка при аутентификации',
          description: error.getRuText?.() || error.message,
          duration: 5,
        })
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          marginBottom: 0,
        }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}
      >
        <Alert
          type='error'
          message={message}
          showIcon
        />
      </motion.div>
      <Form
        layout='vertical'
        name='login-form'
        initialValues={initialCredential}
        onFinish={onLogin}
      >
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Please input your email',
            },
            {
              type: 'email',
              message: 'Please enter a validate email!',
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className='text-primary' />}
          />
        </Form.Item>
        <Form.Item
          name='password'
          label={
            <div className={`${showForgetPassword ? 'd-flex justify-content-between w-100 align-items-center' : ''}`}>
              <span>
                Password
              </span>
              {showForgetPassword && (
                <span
                  className='cursor-pointer font-size-sm font-weight-normal text-muted'
                  onClick={() => onForgetPasswordClick()}
                >
                  Forget Password?
                </span>
              )}
            </div>
          }
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className='text-primary' />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
            loading={loading}
          >
            Sign In
          </Button>
        </Form.Item>
        {extra}
      </Form>
    </>
  )
}
