import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Radio, RadioChangeEvent, Space } from 'antd'
import { useCallback, useState } from 'react'

const REGENERATION_WARNING_MESSAGE = (
  <>
    <span>Если перегенерировать исходную таблицу </span>
    <strong>без сохранения данных</strong>
    <span>, то все изменения в текущей таблице могут быть потеряны. Вы уверены, что хотите перегенерировать таблицу?</span>
  </>
)

type TableGenerationModalProps = {
  isVisible: boolean;
  onClose: () => void;
  generateSpreadsheet: (isWithSavingData: boolean) => void;
}

export function TableGenerationModal({
  isVisible,
  onClose,
  generateSpreadsheet,
}: TableGenerationModalProps) {
  const [isTableGenerationWithSavingData, setIsTableGenerationWithSavingData] = useState<boolean>(true)

  const confirmSpreadsheetGenerationWithoutSavingData = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: 'Предупреждение',
      icon: <ExclamationCircleOutlined />,
      content: REGENERATION_WARNING_MESSAGE,
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: () => {
        generateSpreadsheet(false)
        onClose()
      },
    })
  }, [generateSpreadsheet, onClose])

  const confirmSpreadsheetGeneration = useCallback(() => {
    if (isTableGenerationWithSavingData) {
      generateSpreadsheet(true)
      onClose()
      return
    }

    confirmSpreadsheetGenerationWithoutSavingData()
  }, [confirmSpreadsheetGenerationWithoutSavingData, isTableGenerationWithSavingData, generateSpreadsheet, onClose])

  const cancelSpreadsheetGeneration = useCallback(() => {
    onClose()
    setIsTableGenerationWithSavingData(true)
  }, [onClose])

  return (
    <Modal
      title='Тип перегенерации'
      visible={isVisible}
      onOk={confirmSpreadsheetGeneration}
      onCancel={cancelSpreadsheetGeneration}
      centered
      okText='Да'
      cancelText='Отмена'
      width={300}
    >
      <div>
        <Radio.Group
          value={isTableGenerationWithSavingData}
          onChange={(e: RadioChangeEvent) => {
            setIsTableGenerationWithSavingData(e.target.value)
          }}
        >
          <Space
            direction='vertical'
          >
            <Radio
              value={true}
            >
              С сохранением данных
            </Radio>
            <Radio
              value={false}
            >
              Без сохранения данных
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  )
}
