import React, { useMemo } from 'react'
import parse from 'html-react-parser'
import { CountryEnum, hasCountryIcon } from '../../../../../../utils/country'
import CountryFlagIcon from '../../../CountryFlagIcon'
import { Suggestion } from '../../../../../../api/search/search'
import ProvidersStatusTooltip from '../../common/providers-status-tooltip'
import { Provider } from '../../common/providers/providers'
import { Col, Row, Typography } from 'antd'

const { Title } = Typography

type SuggestionTitleProps = {
  title: string;
  isProviderAvailable: boolean;
  providers: Array<Provider>;
}

function SuggestionTitle({ title, isProviderAvailable, providers }: SuggestionTitleProps) {
  return (
    <Title
      level={4}
      ellipsis={{
        rows: 1,
      }}
      className='m-0'
      style={{
        minWidth: 100,
      }}
    >
      <ProvidersStatusTooltip
        isProviderAvailable={isProviderAvailable}
        providers={providers}
      />
      <h5
        className='title m-0 font-weight-normal'
        style={{
          display: 'inline',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        {parse(`<span class="name-wrapper">${title}</span>`)}
      </h5>
    </Title>
  )
}

type SuggestionPropertiesProps = {
  casId: string;
  catalogueId: string;
  vendor: string;
  countryOfPurchase: CountryEnum;
}

function SuggestionProperties({ casId, catalogueId, vendor, countryOfPurchase }: SuggestionPropertiesProps) {
  return (
    <Row
      className='mt-1'
    >
      <Col
        xs={10}
        xl={7}
      >
        <Row
          align='middle'
        >
          {casId !== 'n/a' && (
            <span
              className={`text-black mr-2 font-weight-normal opacity-0-7`}
              style={propsItemStyle}
            >
              {casId}
            </span>
          )}
          {casId === 'n/a' && (
            '-'
          )}
        </Row>
      </Col>
      <Col
        xs={8}
        xl={8}
      >
        <Row
          align='middle'
        >
          <span
            className={`text-black mr-2`}
            style={propsItemStyle}
          >
            {catalogueId || '-'}
          </span>
        </Row>
      </Col>
      <Col
        xs={6}
        xl={9}
      >
        <Row
          align='top'
          wrap={false}
        >
          <span
            className={`text-primary font-weight-bold`}
            style={propsItemStyle}
          >
            {vendor || '-'}
          </span>
          {countryOfPurchase && (
            <div
              className='ml-2'
              style={{
                display: 'inline-block',
                width: 16,
                minWidth: 16,
              }}
            >
              <CountryFlagIcon
                countryCode={countryOfPurchase}
              />
            </div>
          )}
        </Row>
      </Col>
    </Row>
  )
}

type SearchResultComponentType = {
  suggestion: Suggestion;
}

export const titleStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
}

const propsItemStyle = {
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: 13,
}

export const SearchResultComponent: React.FC<SearchResultComponentType> = ({ suggestion }) => {
  const { data } = suggestion
  const { country_of_purchase } = data
  const countryOfPurchase = ((hasCountryIcon(country_of_purchase) && country_of_purchase) || '') as CountryEnum
  const vendorAvailability = data.vendor_availability

  const providers: Array<Provider> = useMemo(
    () =>
      Object
        .entries(vendorAvailability?.providers_statuses ?? {})
        .map(([providerName, status]) => ({
          name: providerName,
          status,
        })),
    [vendorAvailability?.providers_statuses],
  )
  const isProviderAvailable: boolean = vendorAvailability?.availability_flag ?? false

  return (
    <div
      className='search-result-dropdown-item mr-2'
      style={{
        position: 'relative',
        whiteSpace: 'normal',
      }}
    >
      <SuggestionTitle
        title={data.name}
        isProviderAvailable={isProviderAvailable}
        providers={providers}
      />
      <SuggestionProperties
        casId={data.cas_id}
        catalogueId={data.catalogue_id}
        vendor={data.vendor}
        countryOfPurchase={countryOfPurchase}
      />
    </div>
  )
}
