import { Tooltip } from 'antd'
import { Provider, Providers } from '../providers/providers'
import { CheckCircleOutlined, MinusCircleFilled } from '@ant-design/icons'

type ProvidersStatusProps = {
  isProviderAvailable: boolean;
  providers: Array<Provider>;
}

export function ProvidersStatusTooltip({ isProviderAvailable, providers }: ProvidersStatusProps) {
  return (
    <Tooltip
      placement='bottomLeft'
      title={
        <Providers
          providers={providers}
        />
      }
      style={{ minWidth: 400 }}
      className='mr-2'
    >
      {isProviderAvailable && (
        <CheckCircleOutlined
          className='text-success'
          style={{
            fontSize: 18,
            transform: 'translateY(1px)',
            cursor: 'pointer',
          }}
        />
      )}
      {!isProviderAvailable && (
        <MinusCircleFilled
          className='text-danger'
          style={{
            fontSize: 18,
            transform: 'translateY(1px)',
            cursor: 'pointer',
          }}
        />
      )}
    </Tooltip>
  )
}
