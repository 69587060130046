import { Button, Col } from 'antd'
import { ProposalActionsButton } from '../ProposalActionsButton'
import { useSelector } from 'react-redux'
import { selectBillPdfLink } from '../../../../../../store/generate-table/selectors'
import pdfIconSrc from '../../../../../../assets/svg/pdf-icon.svg'

type BillGenerationActionProps = {
  isGenerationButtonDisabled: boolean;
  generateBill: () => void;
}

export function BillGenerationAction({
  isGenerationButtonDisabled,
  generateBill,
}: BillGenerationActionProps) {
  const billPdfLink = useSelector(selectBillPdfLink)

  return (
    <>
      <Col
        span='8'
      >
        <Button
          type='primary'
          block
          className='mb-4'
          disabled={isGenerationButtonDisabled}
          onClick={generateBill}
        >
          Сгенерировать Счет
        </Button>
      </Col>
      <Col
        span='4'
        className='pl-2'
      >
        <ProposalActionsButton
          link={billPdfLink}
          iconSrc={pdfIconSrc}
        />
      </Col>
      <Col
        span='12'
        className='pl-2'
      >
      </Col>
    </>
  )
}
