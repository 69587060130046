import React from 'react'
import {Button, Switch} from 'antd'
import {useThemeSwitcher} from 'react-css-theme-switcher'
import {setHeaderNavColor, setTheme} from '../../store/theme/reducer'
import {useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {selectTheme} from '../../store/theme/selectors'
import {AUTH_TOKEN} from '../../config/AppConfig'
import { ThemeColorEnum } from '../../views/OrdersView/OrderPositions.utils'

//@ts-ignore
const ListOption = ({name, selector, disabled, vertical}) => (
  <div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
    <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
    <div>{selector}</div>
  </div>
)

export const ThemeConfigurator = () => {
  const dispatch = useDispatch()
  const {switcher, themes} = useThemeSwitcher()
  const currentTheme = useSelector(selectTheme)

  const toggleTheme = (isChecked: boolean) => {
    dispatch(setHeaderNavColor(''))
    const changedTheme = isChecked
      ? ThemeColorEnum.Dark
      : ThemeColorEnum.Light
    dispatch(setTheme(changedTheme))
    localStorage.setItem('theme', changedTheme)
    switcher({ theme: themes[changedTheme] })
  }

  const history = useHistory()

  const doSignOut = () => {
    localStorage.removeItem(AUTH_TOKEN)
    history.push('/auth/login')
  }

  return (
    <>
      <div className='mb-5'>
        <h4 className='mb-3 font-weight-bold'>Navigation</h4>
        {/* @ts-ignore */}
        <ListOption
          name='Dark Theme:'
          selector={
            <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
          }
        />
      </div>
      <div className='mb-5'>
        <Button onClick={() => doSignOut()} type='primary'>Выйти</Button>
      </div>
    </>
  )
}