/* eslint-disable no-unused-vars */
export enum ApiErrorCodeEnum {
    not_defined,
    auth_wrong,
    auth_email_already_exists,
    bad_request,
    get_amo_deal,
    param_id_is_mandatory,
    param_spreadsheet_id_is_mandatory,
    param_email_is_mandatory,
    param_phone_number_is_mandatory,
    generate_proposal,
    internal,
    get_amo_note,
    invalid_spreadsheet_id,
    param_job_id_is_mandatory,
    param_spreadsheet_url_is_mandatory,
    param_proposal_pdf_url_is_mandatory,
    param_proposal_doc_url_is_mandatory,
    param_proposal_spreadsheet_url_is_mandatory,
    param_proposal_spreadsheet_rub_url_is_mandatory,
    invalid_parent_deal_id_and_deal_id,
    param_deal_id_is_mandatory,
    invalid_proposal_id,
    deal_not_found,
    no_items_in_bill,
    param_name_is_mandatory,
    param_phone_is_mandatory,
    param_city_is_mandatory,
    param_contract_number_is_mandatory,
    company_not_found,
    no_company_found_in_moe_delo,
    no_company_in_amocrm_deal,
    no_seller_specified_in_amocrm_deal,
    get_amo_company,
    no_inn_in_company_card,
    failed_creation_bill_moedelo,
    items_contain_not_positive_prices,
}