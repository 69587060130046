import React, { useEffect } from 'react'
import { Col, Divider, InputNumber, Row, Typography } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { CartItem } from '../../../../../store/cart/types'
import parse from 'html-react-parser'
import CountryFlagIcon from '../../CountryFlagIcon'
import { CountryEnum, hasCountryIcon } from '../../../../../utils/country'
import { EnhancedCheckbox } from '../../../../../components/EnhancedCheckbox'

const {
  Title,
  Text,
} = Typography

type CartItemProps = CartItem & {
  itemIndex: number;
  isLastItem?: boolean;
  isDisabledCount: boolean;
  onStartEditHandler?: (index: number) => void;
  scrollTrigger?: () => void;
  onCount: (value: number, itemIndex: number, availability_type?: string) => void;
  onToggleNeedAnalogues: (value: boolean, itemIndex: number) => void;
  onSelectAllNeedAnalogues: () => void;
}

export function CartItemCard({
  catalogue_id,
  vendor,
  count,
  price,
  name,
  partner_url,
  onCount,
  price_currency,
  itemIndex,
  onStartEditHandler,
  isLastItem,
  scrollTrigger,
  package_size,
  isDisabledCount,
  availability_type,
  availability_flag,
  country_of_purchase,
  brand,
  need_analogues = false,
  onToggleNeedAnalogues,
  onSelectAllNeedAnalogues,
}: CartItemProps) {
  const countryOfPurchase: string = (hasCountryIcon(country_of_purchase) && country_of_purchase) || ''

  useEffect(() => {
    isLastItem && scrollTrigger && scrollTrigger()
  }, [])

  const onCountHandler = (value: number) => {
    onCount(value, itemIndex, availability_type)
  }

  const onToggleNeedAnaloguesHandler = (value: boolean) => {
    onToggleNeedAnalogues(value, itemIndex)
  }

  const onStartEdit = (index: number) => {
    onStartEditHandler && onStartEditHandler(index)
  }

  return (
    <div
      className='basket-product-card mb-3'
    >
      <Row className='justify-content-between mb-3'>
        <Col className='flex align-items-center mr-4'>
          <EnhancedCheckbox
            checked={need_analogues}
            hintText='Подобрать аналоги'
            onChange={e => onToggleNeedAnaloguesHandler(e.target.checked)}
            onDoubleClick={onSelectAllNeedAnalogues}
          />
        </Col>
        <Col style={{ paddingTop: 3 }}>
          <span
            style={{ display: 'inline-block' }}
            className='mr-2 text-gray-light opacity-0-5'
          >
            <b>
              {itemIndex + 1}.
            </b>
          </span>
        </Col>
        <Col
          span={12}
          className='mr-auto'
        >
          <Title
            level={4}
            className='mb-2 text-dark d-flex align-items-top'
            style={{ wordBreak: 'break-all' }}
          >
            {partner_url
              ? (
                <a
                  href={partner_url}
                  className='text-dark mr-2'
                  target='_blank'
                  style={{ textDecoration: 'underline' }}
                >
                  {parse(`<span>${name}</span>`)}
                </a>
              )
              : (
                <span className='text-dark mr-2'>
                  {parse(`<span>${name}</span>`)}
                </span>
              )}
            <EditOutlined
              style={{
                fontSize: 17,
                cursor: 'pointer',
                paddingTop: 4,
              }}
              className='text-info'
              onClick={() => onStartEdit(itemIndex)}
            />
          </Title>
          <div className='props-holder'>
            {vendor && (
              <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>
                {vendor}
              </Text>
            )}
            {brand && brand !== vendor && (
              <Text className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'>
                {brand}
              </Text>
            )}
            {countryOfPurchase && (
              <div
                className='mr-2'
                style={{ width: 16, minWidth: 16, display: 'inline-block' }}
              >
                <CountryFlagIcon countryCode={countryOfPurchase as CountryEnum} />
              </div>
            )}
            <Text
              className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white'
            >
              {`${catalogue_id || ''}`}
            </Text>
            <Text
              className='text-info font-weight-bolder rounded pr-2 pt-2 pb-2 product-top-label text-white text-nowrap'
            >
              {`${package_size || ''}`}
            </Text>
            <Text
              className={`font-weight-bold rounded pr-2 pt-2 pb-2 product-top-label ${availability_flag ? 'text-success' : 'text-gray-light opacity-0-5'}`}
            >
              {availability_flag ? 'Available' : 'Non-available'}
            </Text>
          </div>
        </Col>
        <Col className='d-flex align-items-center'>
          <span>
            <strong>
              {price
                ? (price * count).toFixed(2)
                : 'Н/Д'
              }
              {' '}
              {price && price_currency}
            </strong>
          </span>
          <InputNumber
            className='ml-3'
            value={count}
            onChange={value => onCountHandler(value || 0)}
            disabled={isDisabledCount}
          />
          <div
            style={{
              visibility: isDisabledCount ? 'hidden' : 'visible',
            }}
          >
            <DeleteOutlined
              style={{
                fontSize: 21,
                cursor: 'pointer',
              }}
              className='text-danger ml-3'
              onClick={() => onCountHandler(-1)}
            />
          </div>
        </Col>
      </Row>
      {!isLastItem && (
        <Divider />
      )}
    </div>
  )
}
